$shadows: (
  small: 0 2px 8px 0 rgba(0, 0, 0, 0.1),
  medium: 0 4px 16px 0 rgba(0, 0, 0, 0.1),
  large: 0 8px 32px 0 rgba(0, 0, 0, 0.3)
);

$shadow-gradients: (
  small: (
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.1) 2px,
    transparent 10px,
    transparent
  ),
  medium: (
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.1) 4px,
    transparent 16px,
    transparent
  ),
  large: (
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.3) 8px,
    transparent 32px,
    transparent
  )
);

$hero-h1-shadows: (
  xxsmall: 0 1.5rem 3rem 2rem rgba(0, 0, 0, 0.3),
  xsmall: 0 6.5rem 2rem 9rem rgba(0, 0, 0, 0.4),
  small: 0 3.5rem 4.5rem 4.5rem rgba(0, 0, 0, 0.5),
  medium: 0 2.5rem 4rem 2rem rgba(0, 0, 0, 0.5),
  large: 0 3.5rem 4rem 3rem rgba(0, 0, 0, 0.6),
  xlarge: 0 5.5rem 4rem 4rem rgba(0, 0, 0, 0.8)
);

$hero-p-shadows: (
  left: -5em 2.5rem 3rem 3rem rgba(0, 0, 0, 0.5),
  center: 0 2.5rem 3rem 3rem rgba(0, 0, 0, 0.4),
  right-xsmall: 0 2rem 2rem 3rem rgba(0, 0, 0, 0.5),
  right-medium: 3rem 6.5rem 4rem 6rem rgba(0, 0, 0, 0.8),
  right-large: 1rem 4.5rem 1rem 4rem rgba(0, 0, 0, 0.5)
);

/// Gets a shadow from the available sizes (small, medium, or large)
/// Small is default.
///
/// @example scss - medium shadow
///   .block {
///     @include shadow(medium)
///   }
@mixin shadow($level: map-get($shadows, small)) {
  @if map-has-key($shadows, $level) {
    $level: map-get($shadows, $level);
  }

  box-shadow: $level;
}

/// Gets a gradient from the available sizes (small, medium, or large)
/// Can be positioned with a second argument.
///
/// @example scss - large gradient horizontal gradient
///   .block {
///     @include shadow(large, to right)
///   }
///
/// @example scss - small gradient horizontal gradient from right
///   .block {
///     @include shadow(large, -90deg)
///   }
@mixin shadow-gradient(
  $gradient: map-get($shadow-gradients, small),
  $position: false
) {
  @if map-has-key($shadow-gradients, $gradient) {
    $gradient: map-get($shadow-gradients, $gradient);
  }
  @if $position {
    $gradient: $position, $gradient;
  }

  background-image: linear-gradient($gradient);
}

// used to help pass WCAG AA color contrast, text over hero background imges, etc
@mixin text-shadow($offset: 2px, $blur: 0) {
  // TODO: clean up so unit does not need to be sent through the var
  text-shadow: $offset $offset $blur $color-neutral-black;
}

// used to help pass WCAG AA color contrast, text over hero background imges, etc
@mixin text-stroke($width: 1, $color: $color-neutral-black) {
  $shadow: ();
  @for $i from -$width through $width {
    $shadow: append($shadow, #{$width}px #{$i}px $color, comma);
    $shadow: append($shadow, #{-$width}px #{$i}px $color, comma);
    $shadow: append($shadow, #{$i}px #{$width}px $color, comma);
    $shadow: append($shadow, #{$i}px #{-$width}px $color, comma);
  }
  text-shadow: $shadow;
}

@mixin hero-h1-shadow($level: map-get($hero-h1-shadows, small)) {
  @if map-has-key($hero-h1-shadows, $level) {
    $level: map-get($hero-h1-shadows, $level);
  }

  box-shadow: $level;
}

// hero-h1-shadow (default: small)
@mixin hero-p-shadow($level: map-get($hero-p-shadows, right)) {
  @if map-has-key($hero-p-shadows, $level) {
    $level: map-get($hero-p-shadows, $level);
  }

  box-shadow: $level;
}
