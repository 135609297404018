@import '../settings/settings';

.nmx-print {
  @media screen {
    display: none !important;
  }
}

.nmx-no-print {
  @media print {
    display: none !important;
  }
}
