// Legacy - delete

@import '../mixins/responsive.mixin';

@keyframes animate-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

.loader {
  @include desktop-1920(
    (
      display: block,
      position: relative,
      height: 200px,
      width: 100%
    )
  );

  @include phone(
    (
      height: 160px
    )
  );

  &::before,
  &::after {
    @include desktop-1920(
      (
        content: '',
        display: inline-block,
        position: absolute,
        top: 50%,
        left: 50%,
        border: 3px solid transparent,
        animation-name: animate-spin,
        animation-duration: 1250ms,
        animation-timing-function: cubic-bezier(0.12, 0.33, 0.89, 0.68),
        animation-iteration-count: infinite,
        border-radius: 1000px
      )
    );

    @include phone(
      (
        border-width: 3px
      )
    );
  }

  &::before {
    @include desktop-1920(
      (
        border-bottom-color: $color-primary-nm-blue,
        border-left-color: $color-primary-nm-blue,
        height: 62px,
        width: 62px,
        margin: -31px 0 0 -31px
      )
    );

    @include phone(
      (
        height: 62px,
        width: 62px,
        margin: -31px 0 0 -31px
      )
    );
  }

  &::after {
    @include desktop-1920(
      (
        border-top-color: $color-system-cta-yellow,
        border-right-color: $color-system-cta-yellow,
        animation-direction: reverse,
        height: 52px,
        width: 52px,
        margin: -26px 0 0 -26px
      )
    );

    @include phone(
      (
        height: 52px,
        width: 52px,
        margin: -26px 0 0 -26px
      )
    );
  }
}
