// Helper Classes

.nmx-align-left {
  text-align: left;
}

.nmx-align-right {
  text-align: right;
}

.nmx-align-center {
  text-align: center;
}

.nmx-right {
  float: right;
}

.nmx-left {
  float: left;
}

.nmx-clear {
  clear: both;
}

.nmx-clear-left {
  clear: left;
}

.nmx-clear-right {
  clear: right;
}

.nmx-block {
  display: block;
}

.nmx-assistive-text {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
}

.nmx-uppercase {
  text-transform: uppercase;
}

// Doubling the specificity here, because nmx-p has font-style:normal, which beats this out otherwise.
.nmx-italic {
  &#{&} {
    font-style: italic;
  }
}

.nmx-hide {
  display: none;
}

.nmx-print {
  display: none;
}

.nmx-no-mobile [href^='tel:'],
.nmx-no-mobile [href^='sms:'] {
  color: inherit;
  cursor: inherit;
  text-decoration: none;

  &:hover {
    color: inherit;
  }
}

.clearfix::after {
  display: table;
  clear: both;
  content: '';
}
