@import 'breakpoints';
@import 'units';

// - FONT SIZES
$font-size-base: 16px;

$font-sizes: (
  h1: rem-calc(40px),
  h2: rem-calc(32px),
  h3: rem-calc(28px),
  h4: rem-calc(24px),
  h5: rem-calc(21px),
  h6: rem-calc(16px)
); // TODO: finish up framing out heading font-size/line-height structure

$font-sizes-medium: (
  h1: rem-calc(32px),
  h2: rem-calc(28px),
  h3: rem-calc(24px),
  h4: rem-calc(20px),
  h5: rem-calc(18px),
  h6: rem-calc(16px)
);

$font-sizes-small: (
  h1: rem-calc(28px),
  h2: rem-calc(22px),
  h3: rem-calc(20px),
  h4: rem-calc(18px),
  h5: rem-calc(16px),
  h6: rem-calc(14px)
);

// - LINE HEIGHTS
$font-line-height-base: rem-calc(24px);

$font-line-heights: (
  h1: rem-calc(48px),
  h2: rem-calc(42px),
  h3: rem-calc(36px),
  h4: rem-calc(32px),
  h5: rem-calc(30px),
  h6: rem-calc(24px)
);

$font-line-heights-medium: (
  h1: rem-calc(40px),
  h2: rem-calc(36px),
  h3: rem-calc(32px),
  h4: rem-calc(28px),
  h5: rem-calc(26px),
  h6: rem-calc(24px)
);

$font-line-heights-small: (
  h1: rem-calc(36px),
  h2: rem-calc(32px),
  h3: rem-calc(28px),
  h4: rem-calc(26px),
  h5: rem-calc(24px),
  h6: rem-calc(22px)
);

// - LETTER SPACING
$font-letter-spacing-base: 0;
$font-letter-spacing: 1px;

// - FONT WEIGHTS
$weight-thin: 100;
$weight-light: 200;
$weight-300: 300;
$weight-regular: 400;
$weight-medium: 500;
$weight-semibold: 600; // TODO: build in stock weights from font: https://commercialtype.com/catalog/guardian/guardian_sans_headline

// - FONT STACK
$font-family-sans-serif: 'Guardian Sans', 'Helvetica Neue', Helvetica,
  sans-serif;
$font-family-text-sans-serif: 'Guardian Sans', 'Helvetica Neue', Helvetica,
  sans-serif; // Guardian Text Sans

$font-families: (
  h1: $font-family-sans-serif,
  h2: $font-family-sans-serif,
  h3: $font-family-sans-serif,
  h4: $font-family-sans-serif,
  h5: $font-family-text-sans-serif,
  h6: $font-family-text-sans-serif
);

// // @import "@nmx/template/mixins/fonts"; // TODO: double-check fonts are being imported from nmx-template
// TODO: don't assign here, should be imported from nmx-template
// - FONT IMPORTS
$guardian-sans-thin: (
  name: 'Guardian Sans',
  file: 'GuardianSans-Thin',
  style: normal,
  weight: $weight-thin
);

$guardian-sans-thin-italic: (
  name: 'Guardian Sans',
  file: 'GuardianSans-Thin-Italic',
  style: italic,
  weight: $weight-thin
);

$guardian-sans-light: (
  name: 'Guardian Sans',
  file: 'GuardianSans-Light',
  style: normal,
  weight: $weight-light
);

$guardian-sans-light-italic: (
  name: 'Guardian Sans',
  file: 'GuardianSans-Light-Italic',
  style: italic,
  weight: $weight-light
);

$guardian-sans-regular: (
  name: 'Guardian Sans',
  file: 'GuardianSans-Regular',
  style: normal,
  weight: $weight-regular
);

$guardian-sans-regular-italic: (
  name: 'Guardian Sans',
  file: 'GuardianSans-Regular-Italic',
  style: italic,
  weight: $weight-regular
);

$guardian-sans-medium: (
  name: 'Guardian Sans',
  file: 'GuardianSans-Medium',
  style: normal,
  weight: $weight-medium
);

$guardian-sans-medium-italic: (
  name: 'Guardian Sans',
  file: 'GuardianSans-Medium-Italic',
  style: italic,
  weight: $weight-medium
);

$guardian-sans-semibold: (
  name: 'Guardian Sans',
  file: 'GuardianSans-SemiBold',
  style: normal,
  weight: $weight-semibold
);

$guardian-sans-semibold-italic: (
  name: 'Guardian Sans',
  file: 'GuardianSans-SemiBold-Italic',
  style: italic,
  weight: $weight-semibold
);

$guardian-text-sans-regular: (
  name: 'Guardian Sans',
  // Guardian Text Sans
    file: 'GuardianTextSans-Regular-Web',
  style: normal,
  weight: $weight-regular
);

$guardian-text-sans-medium: (
  name: 'Guardian Sans',
  // Guardian Text Sans
    file: 'GuardianTextSans-Medium-Web',
  style: normal,
  weight: $weight-medium
);

// TODO: get Tandelle imported here
// $tandelle-regular: (
//   name: 'Tandelle Regular',
//   file: 'Tandelle-Regular',
//   style: normal,
//   weight: $weight-medium
// );

//
// FONT LIST
// Each font object declared above is passed into $font-list below.
//
$font-list: (
  $guardian-sans-thin-italic,
  $guardian-sans-thin,
  $guardian-sans-light-italic,
  $guardian-sans-light,
  $guardian-sans-regular-italic,
  $guardian-sans-regular,
  $guardian-sans-medium-italic,
  $guardian-sans-medium,
  $guardian-sans-semibold,
  $guardian-sans-semibold-italic,
  $guardian-text-sans-regular,
  $guardian-text-sans-medium
);

@mixin font-face($font) {
  // $font-path: '<%=templateBasePath%>/fonts'; // '/assets/fonts'
  // $font-path:'/assets/fonts';
  $font-path: 'https://plan.northwesternmutual.com/static/assets/public/fonts'; // TODO: this is taken from CX/Couturier, we should use a local nm.com version
  $exts: 'woff2', 'woff';
  $name: map-get($font, 'name');
  $weight: map-get($font, 'weight');
  $file: map-get($font, 'file');
  $style: map-get($font, 'style');
  $src: null;
  $path: $font-path + '/' + $file;

  @each $ext in $exts {
    $src: append(
      $src,
      url(quote($path + '.' + $ext)) format(quote($ext)),
      comma
    );
  }

  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
  }
}

// @example scss
///   h1 { @include font-header(h1); }
@mixin font-header($header) {
  font-family: map-get($font-families, $header);
  font-size: map-get($font-sizes-small, $header);
  font-weight: $weight-medium;
  letter-spacing: $font-letter-spacing;
  line-height: map-get($font-line-heights-small, $header);

  @include breakpoint(medium) {
    font-size: map-get($font-sizes-medium, $header);
    line-height: map-get($font-line-heights-medium, $header);
  }
  @include breakpoint(large) {
    font-size: map-get($font-sizes, $header);
    line-height: map-get($font-line-heights, $header);
  }
}

/// @example scss
///   .cell { @include font-tf; }
@mixin font-tf {
  font-family: $font-family-text-sans;
  font-feature-settings: 'tnum';
}

// @example scss
///   .sample-list-item { @include font-p(); }
// duplicated in default
@mixin font-p {
  color: $color-neutral-gray-29;
  // TODO: consolidate these styles
  font-size: rem-calc(14px);
  font-weight: $weight-light;
  letter-spacing: $font-letter-spacing;
  line-height: rem-calc(22px);
  @include breakpoint(small) {
    font-size: rem-calc($font-size-base);
    line-height: rem-calc($font-line-height-base);
  }
  @include breakpoint(xlarge) {
    font-size: rem-calc(20px);
    line-height: rem-calc(30px);
  }
}

// @example scss
///   .sample-list-item { @include font-eyebrow(); }
// duplicated in default
@mixin font-eyebrow {
  color: $color-neutral-gray-29;
  // TODO: consolidate these styles
  font-size: rem-calc(12px);
  font-weight: $weight-light;
  letter-spacing: $font-letter-spacing;
  line-height: rem-calc(16px);
  @include breakpoint(medium) {
    font-size: rem-calc(14px);
    line-height: rem-calc(22px);
  }
}

// @example scss
///   .sample-element { @include font-a(); }
// duplicated in default
@mixin font-a {
  // TODO: consolidate these styles
  @include font-p();
  color: $color-system-cta-blue;
  font-size: rem-calc(14px);
  font-weight: $weight-regular;
  letter-spacing: $font-letter-spacing;
  line-height: rem-calc(22px);
  text-decoration: none;

  // @include breakpoint(medium) {
  //   font-size: rem-calc($font-size-base);
  //   line-height: rem-calc($font-line-height-base);
  // }

  // @include breakpoint(large) {
  //   font-size: rem-calc(20px);
  //   line-height: rem-calc(30px);
  // }

  &:hover {
    color: $color-system-cta-hover-blue;
    text-decoration: underline;
  }
  &:active {
    color: $color-system-cta-active-blue;
  }
}

// @example scss
///   .sample-element { @include font-a-ghost(); }
// duplicated in default
@mixin font-a-ghost {
  // TODO: consolidate these styles
  @include font-p();
  color: $color-neutral-white;
  // font-size: rem-calc(14px);
  font-weight: $weight-regular;
  letter-spacing: $font-letter-spacing;
  // line-height: rem-calc(22px);
  text-decoration: none;

  // @include breakpoint(medium) {
  //   font-size: rem-calc($font-size-base);
  //   line-height: rem-calc($font-line-height-base);
  // }

  // @include breakpoint(large) {
  //   font-size: rem-calc(20px);
  //   line-height: rem-calc(30px);
  // }

  &:hover {
    color: $color-neutral-gray-95;
    text-decoration: underline;
  }
  &:active {
    color: $color-neutral-gray-59;
  }
}

// @example scss
///   .sample-element { @include font-a-small(); }
// duplicated in default
@mixin font-a-small {
  // TODO: consolidate these styles
  // @include font-p();
  color: $color-system-cta-blue;
  font-size: rem-calc(14px);
  font-weight: $weight-regular;
  letter-spacing: $font-letter-spacing;
  line-height: rem-calc(22px);
  text-decoration: none;

  // @include breakpoint(medium) {
  //   font-size: rem-calc($font-size-base);
  //   line-height: rem-calc($font-line-height-base);
  // }

  // @include breakpoint(large) {
  //   font-size: rem-calc(20px);
  //   line-height: rem-calc(30px);
  // }

  &:hover {
    color: $color-system-cta-hover-blue;
    text-decoration: underline;
  }
  &:active {
    color: $color-system-cta-active-blue;
  }
}

// @example scss
///   .sample-hero--heading { @include hero-h1(); }
@mixin hero-h1 {
  // TODO: this type face would be the most common hero type face... make sure is reflected in nmx-template
  font-size: rem-calc(40px);
  letter-spacing: rem-calc(2px);
  line-height: rem-calc(40px);
  // @include breakpoint(small) {
  //   font-size: rem-calc(50px);
  //   line-height: rem-calc(50px);
  // }
  @include breakpoint(medium) {
    font-size: rem-calc(60px);
    letter-spacing: rem-calc(4px);
    line-height: rem-calc(56px);
  }
  @include breakpoint(large) {
    font-size: rem-calc(90px);
    line-height: rem-calc(80px);
  }
}

@mixin hero-h1-left {
  font-size: rem-calc(40px);
  letter-spacing: rem-calc(1px);
  line-height: rem-calc(40px);
  // @include breakpoint(small) {
  //   font-size: rem-calc(52px);
  //   line-height: rem-calc(48px);
  // }
  @include breakpoint(medium) {
    font-size: rem-calc(62px);
    line-height: rem-calc(55px);
  }
  // @include breakpoint(large) {
  //   font-size: rem-calc(80px);
  //   line-height: rem-calc(72px);
  // }
  @include breakpoint(xlarge) {
    font-size: rem-calc(70px);
    line-height: rem-calc(60px);
  }
}

@mixin hero-h1-split {
  font-size: rem-calc(40px);
  letter-spacing: rem-calc(1px);
  line-height: rem-calc(40px);
  @include breakpoint(small) {
    // font-size: rem-calc(52px);
    // line-height: rem-calc(48px);
  }
  @include breakpoint(medium) {
    font-size: rem-calc(48px);
    line-height: rem-calc(48px);
  }
  @include breakpoint(xlarge) {
    font-size: rem-calc(68px);
    line-height: rem-calc(68px);
  }
}

@mixin hero-h1-center {
  font-size: rem-calc(40px);
  letter-spacing: rem-calc(1px);
  line-height: rem-calc(40px);
  @include breakpoint(small) {
    // font-size: rem-calc(52px);
    // line-height: rem-calc(48px);
  }
  @include breakpoint(medium) {
    font-size: rem-calc(50px);
    line-height: rem-calc(50px);
  }
  @include breakpoint(large) {
    font-size: rem-calc(62px);
    line-height: rem-calc(55px);
  }
  @include breakpoint(xlarge) {
    font-size: rem-calc(70px);
    line-height: rem-calc(60px);
  }
}

@mixin hero-h1-right {
  font-size: rem-calc(30px);
  letter-spacing: rem-calc(1px);
  line-height: rem-calc(38px);
  @include breakpoint(small) {
    font-size: rem-calc(46px);
    line-height: rem-calc(50px);
  }
  @include breakpoint(medium) {
    font-size: rem-calc(40px);
    line-height: rem-calc(42px);
  }
  @include breakpoint(large) {
    font-size: rem-calc(52px);
    line-height: rem-calc(48px);
  }
  @include breakpoint(xlarge) {
    font-size: rem-calc(60px);
    line-height: rem-calc(56px);
  }
}

// @example scss
///   .sample-element-text { @include jumbo-type(); }
@mixin jumbo-type {
  font-size: rem-calc(60px);
  line-height: rem-calc(50px);
  @include breakpoint(medium) {
    font-size: rem-calc(50px);
    line-height: rem-calc(48px);
  }
  @include breakpoint(large) {
    font-size: rem-calc(80px);
    line-height: rem-calc(70px);
  }
}
