// any misc mixins (specific mixins should go in respective directory)

@mixin aspect-ratio-height($width, $height) {
  height: ($height / $width) * 100 + vw;
}

@mixin is-safari {
  @at-root {
    @supports (background: -webkit-named-image(i)) {
      /** Safari fix */
      & {
        @content;
      }
    }
  }
}
