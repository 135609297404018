$base-namespace: '.nmx-base' !default;

@mixin namespace($namespace) {
  @if $namespace {
    #{$namespace} {
      @content;
    }
  } @else {
    @content;
  }
}
