// namespacing all new homepage sections with "-home" for modularity
.nmx-section-articles {
  background-color: $color-neutral-gray-95;

  // @include breakpoint(medium) {
  //   padding-top: rem-calc(50px);
  //   padding-bottom: rem-calc(50px);
  // }

  .nmx-container {
    @include breakpoint($max: large) {
      max-width: 100%;
    }

    h2 {
      margin-bottom: rem-calc(40px);
      @include breakpoint(medium) {
        margin-bottom: rem-calc(50px);
      }
    }
  }

  .card-article {
    &:not(.card-article-last) {
      padding-bottom: rem-calc(20px);
    }
    @include breakpoint(medium) {
      padding-bottom: rem-calc(20px);
    }

    &-container {
      position: relative;
      display: block;
      width: 100%;

      &:hover {
        @include shadow(medium);
        text-decoration: none;

        .card-article-content {
          background-color: $color-neutral-white;
        }

        .card-article-content-title {
          color: $color-system-cta-blue;
        }
      }
    }

    &-image {
      display: block;
      width: 100%;

      &-container {
        &::before {
          position: absolute;
          display: block;
          width: 100%;
          height: 70%;
          background-color: transparent;
          background-position: 50% 50%;
          @media all and (-ms-high-contrast: none) {
            background-size: 30% 50%;
          }
          background-repeat: no-repeat;
          background-size: 100% 40%;
          content: '';
          @include breakpoint(medium) {
            height: 60%;
            background-size: 50% 50%;
            @media all and (-ms-high-contrast: none) {
              background-size: 30% 50%;
            }
          }
        }
      }
    }

    // eyebrow tag
    &-tag {
      position: absolute;
      left: 0;
      display: inline-flex;
      height: 2rem;
      align-items: center;
      padding: rem-calc(10px);
      background: $color-section-background-blue;
      color: $color-neutral-white;
      font-size: 10px;
      line-height: 14px;
      text-transform: uppercase;

      &::after {
        position: absolute;
        top: 0;
        right: rem-calc(-20px);
        border-bottom: rem-calc(32px) solid transparent;
        border-left: rem-calc(20px) solid $color-section-background-blue;
        content: '';
      }
    }

    &-content {
      padding: 1rem;
      @include breakpoint(large) {
        padding: rem-calc(24px);
      }
      &-title {
        color: $color-neutral-gray-29;
        @include breakpoint(small) {
          min-height: rem-calc(72px);
        }
        @include breakpoint(medium) {
          min-height: rem-calc(90px);
        }
      }
    }
    &.calculator {
      &.calculator-life-insurance {
        .card-article-image-container {
          &::before {
            background-image: url(/assets/images/icons/svg/icon-umbrella-with-border-blue-bg.svg);
          }
        }
      }
      &.calculator-retirement-savings {
        .card-article-image-container {
          &::before {
            background-image: url(/assets/images/icons/svg/icon-beachchair-sun-border-blue-bg.svg);
          }
        }
      }
    }
  }

  // &-see-more-cta {
  //   &-container {
  //     text-align: center;
  //   }
  //   // append the chevron
  //   &::after {
  //     position: relative;
  //     top: 3px; // 6px (chevron-right-new)
  //     display: inline-block;
  //     width: 12px; // 13px (chevron-right-new)
  //     height: 14px; // 16px (chevron-right-new)
  //     margin-left: 5px;
  //     background-image: url('/assets/images/icons/utility/chevron-right.svg'); // TODO: chevron-right-new.svg (having troubles with the polyline filter, so will come back to this)
  //     background-repeat: no-repeat;
  //     background-size: 50%;
  //     content: ' ';
  //     filter: invert(32%) sepia(88%) saturate(1240%) hue-rotate(185deg) brightness(94%) contrast(87%); // color to #1778ca ($blue-link)
  //   }
  //   &:hover,
  //   &:focus {
  //     color: #1566aa;
  //     text-decoration: underline;
  //     &::after {
  //       filter: invert(31%) sepia(46%) saturate(2090%) hue-rotate(184deg) brightness(89%) contrast(89%); // color to #1566aa ($blue-link)
  //     }
  //   }
  // }
}
