@import 'units';

$grid-column-count: 12 !default;
$grid-container-width: rem-calc(1200px) !default;
$header-max-width: rem-calc(1440px) !default;
$absolute-canvas-max-width: rem-calc(2000px) !default;

//TODO: explore bumping content to 1440px at 1920 breakpoint? also, what would xxlarge grid-gutter be? currently 16px at 1200px content max-width (previously set to 24px)?

$grid-gutters: (
  xsmall: rem-calc(4px),
  small: rem-calc(4px),
  medium: rem-calc(8px),
  large: rem-calc(10px),
  large1200: rem-calc(10px),
  xlarge: rem-calc(16px),
  xxlarge: rem-calc(16px)
) !default;

$grid-outer-gutters: (
  xsmall: rem-calc(16px),
  small: rem-calc(16px),
  medium: rem-calc(20px),
  large: rem-calc(24px),
  large1200: rem-calc(24px),
  xlarge: rem-calc(40px),
  xxlarge: rem-calc(40px)
) !default;

$grid-mobile-content-padding: 5%;

@mixin col($flex: 1, $max-width: none) {
  max-width: $max-width;
  flex: $flex;
  overflow-wrap: break-word;
}
