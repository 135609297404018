// Set root font size to browser's font size setting
html,
body {
  font-size: 100%;
}

// Remove default margin in all browsers
body {
  // disable ie vertical scrollbar
  // @media all and (-ms-high-contrast: none) {
  //   overflow: hidden;
  // }

  overflow: auto;
  margin: 0;
  zoom: unset !important; // Disable global zoom. TODO: can remove this line once site-wide scaling is entirely removed
  .is-mobile-active {
    overflow: hidden;
  }
}

.nmx-body-container {
  // set absolute max width of canvas
  max-width: $absolute-canvas-max-width;
  margin: 0 auto;
  overflow-x: hidden;
}

.safari-inflow-recaptcha-disclaimer {
  margin-bottom: 1rem !important; // override legacy styles
  padding: 1rem !important; // override legacy styles
  background-color: $color-neutral-gray-99;
  &__copy {
    margin-bottom: 0 !important; // override legacy styles
  }
}

// ========================
// OVERWRITE
// ========================
// Overwriting old NM.COM default styles
// TODO: remove once nmx-template fully utilized. See https://git.nmlv.nml.com/nmx/nmx-template/-/issues/3
// ========================
section {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: left;
  align-items: flex-start;
  -webkit-justify-content: left;
  justify-content: left;
  padding: 0;
  background-color: unset;
  -webkit-box-align: start;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-box-pack: start;
  color: unset;
  -ms-flex-align: left;
  -ms-flex-pack: left;
  text-align: left;
}

picture {
  display: block;
  overflow: unset;
  background-color: transparent;
}

// ==================
// namespace resets for some of the regular NM.Com default styles that are getting overwritten by nmcom.home.css
// ==================

// TODO: is this still needed? LEGACY!

#nmx-login-window .form-submit-button,
.nm-search-page--form button.is-yellow, // this is dirty but necessary, since the existing architecture doesn't provide many hooks
.nm-search-page--content .nm-rep-card button.is-yellow, // this is dirty but necessary, since the existing architecture doesn't provide many hooks
.nm-search-page--content .nm-rep-card button.is-navy {
  font-weight: $weight-medium;
  // this is dirty but necessary, since the existing architecture doesn't provide many hooks
  text-transform: uppercase;
}

#nmx-login-window .form-submit-button,
.nm-search-page--form button.is-yellow, // this is dirty but necessary, since the existing architecture doesn't provide many hooks
.nm-search-page--content .nm-rep-card button.is-yellow {
  // this is dirty but necessary, since the existing architecture doesn't provide many hooks
  color: $color-neutral-black;
}

#modal-window-nm-login-username,
#modal-window-nm-login-password,
.nm-search-page--content .general-form .general-form__input input, // this is dirty but necessary, since the existing architecture doesn't provide many hooks
.nm-search-page--content .general-form .general-form__input select {
  // this is dirty but necessary, since the existing architecture doesn't provide many hooks
  font-family: sans-serif;
}
