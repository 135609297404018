body.modal-is-open {
  position: fixed;
  width: 100%;
  overflow: hidden;
}

// reduced default
ul.reduced {
  padding: 0;
  // tag-qualified for higher specificity
  margin: 0;
  list-style: none;
}

.background-chevron-bottom {
  z-index: 1;
  width: 100%;
  height: rem-calc(15px);
  &::before {
    position: absolute;
    left: 0;
    display: block;
    width: 50%;
    height: rem-calc(15px);
    border-right: 15px solid transparent;
    border-bottom: 15px solid $color-neutral-white;
    border-left: 0 solid transparent;
    content: ' ';
  }
  &::after {
    position: absolute;
    right: 0;
    display: block;
    width: 50%;
    height: rem-calc(15px);
    border-right: 0 solid transparent;
    border-bottom: 15px solid $color-neutral-white;
    border-left: 15px solid transparent;
    content: ' ';
  }
}
