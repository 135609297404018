// currently used on main search lead form // TODO: move global form styles to /base

.nmx-form {
  // TODO: move to global styles?
  .nmx-row {
    margin-bottom: rem-calc(20px);
  }
  fieldset {
    padding: 0;
    border: none;
  }
  legend {
    display: none;
  }

  // TODO: normalize some of these styles
  // some of these styles are used in the search FR form, so maybe should be moved to a separate area
  &__input {
    font-weight: $weight-light;
    letter-spacing: rem-calc(1px);
    label {
      margin-left: 1rem;
    }
    input,
    select,
    textarea {
      &:not([type='radio']) {
        width: 100%;
        padding: 0.5rem;
        margin-top: 0.5rem;
        font-size: 100%;
        line-height: 1.2;
      }
    }
  }
  &__radio-button-label {
    margin-right: 0.5rem;
  }
  &__validation-error {
    display: block;
    padding-top: rem-calc(5px);
    color: $color-system-negative-error;
    font-size: (rem-calc(14px * 0.8));
    @include breakpoint(small) {
      font-size: (rem-calc($font-size-base * 0.8));
    }
  }
  &-form-submission {
    .submit-error {
      color: $color-system-negative-error;
    }
  }

  // specific form styles
  // &-lead {

  // }
  // &-serviceRequest {

  // }
}
