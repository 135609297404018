@import 'units';

// TODO: we won't really be using these grid breakpoints until full template inheritance

// Breakpoints are mobile-first; smallest breakpoint should be 0
// Breakpoints are defined in em to respect browser font-size setting
// See https://zellwk.com/blog/media-query-units/
$breakpoints: (
  xsmall: px-to-em(0),
  small: px-to-em(480px),
  medium: px-to-em(769px),
  large: px-to-em(1024px),
  large1200: px-to-em(1200px),
  xlarge: px-to-em(1440px),
  xxlarge: px-to-em(1920px)
) !default;

/// Wraps content within a media query.
/// Breakpoints are converted to ems.
///
/// @example scss - Medium up
///   @include breakpoint(medium) {
///     .content { color: green; }
///   }
///
/// @example scss - Medium down
///   @include breakpoint($max: large) {
///     .content { color: green; }
///   }
///
/// @example scss - Medium only
///   @include breakpoint(medium, large) {
///     .content { color: green; }
///   }
///
/// @example scss - Small only
///   @include breakpoint(small, medium) {
///     .content { color: green; }
///   }
///
/// @example scss - Large up, portrait
///   @include breakpoint(large, $orientation: portrait) {
///     .content { color: green; }
///   }
///
/// @example scss - Print only
///   @include breakpoint($type: print) {
///     .content { color: green; }
///   }
///
/// @example scss - Small to large, landscape, screen
///   @include breakpoint(small, large, landscape, screen) {
///     .content { color: green; }
///   }
///
/// @example scss - 300px up
///   @include breakpoint(300px) {
///     .content { color: green; }
///   }
@mixin breakpoint($min: 0, $max: 0, $orientation: false, $type: all) {
  $query: $type;

  // If a size keyword is used (e.g., "medium"), replace it with the
  // corresponding value defined in $breakpoints
  @if map-has-key($breakpoints, $min) {
    $min: map-get($breakpoints, $min);
  }
  @if map-has-key($breakpoints, $max) {
    $max: map-get($breakpoints, $max);
  }

  // If px values are used, convert them to em
  @if $min > 0 and type-of($min) == number and unit($min) != 'em' {
    $min: px-to-em($min);
  }
  @if $max > 0 and type-of($max) == number and unit($max) != 'em' {
    $max: px-to-em($max);
  }

  // $max is an exclusive value; subtract by 0.001px to avoid cascade overlap conflict
  $max: $max - px-to-em(0.016);

  // Build queries list
  @if $min > 0 {
    $query: $query + ' and (min-width: #{$min})';
  }
  @if $max > 0 {
    $query: $query + ' and (max-width: #{$max})';
  }
  @if $orientation {
    $query: $query + ' and (orientation: #{$orientation})';
  }

  @if $query == all {
    @content;
  } @else {
    @media #{$query} {
      @content;
    }
  }
}
