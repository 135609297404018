// pcg colors
$pcg-dark-blue-1: #0f1117;
$pcg-dark-blue-2: #0f2160;
$pcg-dark-blue-3: #00487c;

// $pcg-form-control-bg-blue: #242F51;

$pcg-gold-1: #e1c88f;
$pcg-gold-2: #f2dfac;

//TODO: these scss vars lives in template, duplicated here... figure out how to import nmx-template scss vars, settings, etc
// We'll need all of the base stuff in order for local media queries to work
$color-neutral-white: #fff; // white
$color-neutral-gray-99: #f8fafc; // gray 99% white
$color-neutral-gray-59: #979797; // gray 59% white
$color-neutral-gray-46: #767676; // gray 46% white
$color-neutral-gray-29: #4a4a4a; // gray 29% white
$color-neutral-black: #000;

$color-system-cta-blue: #1570bc;
$color-system-cta-hover-blue: #0e497b;

$color-hero-text-stroke: #2e353a;

// mixins
@mixin pcg-bg-gradient {
  background: rgb(2, 0, 36);
  background: linear-gradient(
    193deg,
    $pcg-dark-blue-1 0%,
    $pcg-dark-blue-2 50%,
    $pcg-dark-blue-1 100%
  );
}

@mixin pcg-rule-gradient {
  background: rgb(189, 162, 99);
  background: linear-gradient(
    90deg,
    $pcg-gold-1 0%,
    $pcg-gold-2 50%,
    $pcg-gold-1 100%
  );
}
// 10% darker hover on all 3 RGBa values
@mixin pcg-rule-gradient-hover {
  background: rgb(189, 162, 99);
  background: linear-gradient(
    90deg,
    shade($pcg-gold-1, 5%) 0%,
    shade($pcg-gold-2, 5%) 50%,
    shade($pcg-gold-1, 5%)
  ); // TODO: darken RGB values programatically
}

.nmx-button {
  &.nmx-pcg {
    border: none;
    color: $color-neutral-gray-29;
    letter-spacing: 3px; // TODO: need rems here // need nmx-template Sass values here.
    text-transform: uppercase;
    @include pcg-rule-gradient;

    // base button states
    &:hover {
      border: none; // 1px solid $button-color-yellow-hover;
      @include pcg-rule-gradient-hover;
    }

    &:focus {
      border: none;
    }

    &:active {
      border: none;
    }
  }
}

.nmx-link {
  &.nmx-pcg {
    color: $pcg-dark-blue-3;
  }
}
