@import '../settings/settings';
// TODO: double-check fonts are being imported from nmx-template

@each $font in $font-list {
  @include font-face($font);
}

@font-face {
  font-family: 'tandelle';
  font-weight: 400;
  src: local('Tandelle-Regular'),
    url('/template/assets/<%=templateVersion%>/fonts/tandelle/Tandelle-Regular.woff2')
      format('woff2'),
    url('/template/assets/<%=templateVersion%>/fonts/tandelle/Tandelle-Regular.woff')
      format('woff');
}

$font-family-sans-serif-hero: 'tandelle', 'Guardian Sans', 'Helvetica Neue',
  Helvetica, sans-serif;
