// **************************
// global layout print styles
// **************************

@media print {
  // print reset
  * {
    position: relative !important;
    z-index: 1 !important;
    top: 0 !important;
    left: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    background-color: #fff !important;
    color: #000 !important;
    float: none !important;
    font-family: $font-family-sans-serif !important;
    font-size: 14px !important;
    font-weight: normal !important;
    letter-spacing: normal !important;
    line-height: 22px !important;
    text-align: left !important;
    text-transform: none !important;
  }

  // show/hide specific elements
  .nmx-no-print,
  .nmx-assistive-text,
  #header-logo,
  #nmx-client-login,
  #nmx-search-console,
  #nmx-mobile-nav-container,
  #nmx-nav-link-login-button-container,
  .nmx-login-button,
  .nm-expandable-toast {
    // hide main header
    // TODO: use better header hook (id) when template work is at a good spot
    display: none !important;
  }

  .nmx-print {
    display: block !important;
  }

  .nmx-header {
    display: block !important;
    width: 100% !important;
    max-width: 90rem !important;
    height: auto !important;
    flex: unset !important;
    border: none !important;
    margin: 0 auto !important;
    .nm-login-button--component-holder, // TODO: use better header hook (id) when template work is at a good spot
    .header--hamburger, // TODO: use better header hook (id) when template work is at a good spot
    .header--toggle, // TODO: use better header hook (id) when template work is at a good spot
    & > nav {
      display: none !important;
    }
    // .header--logo {
    //   display: block !important;
    //   width: 100% !important;
    //   align-items: start !important;
    //   justify-content: unset !important;
    //   text-align: left !important;
    //   .img {
    //     width: 200px !important;
    //     margin-bottom: -10px !important;
    //   }
    // }
  }

  // style general elements // TODO: these will all have to be dialed in once we have a standard style guide
  .nmx-section {
    padding: 1rem 0 !important;
  }
  .nmx-hero-h1 {
    font-family: 'tandelle', 'Guardian Sans', 'Helvetica Neue', Helvetica,
      sans-serif !important;
    font-size: rem-calc(40px) !important;
    letter-spacing: 1px !important;
    line-height: rem-calc(50px) !important;
    text-transform: uppercase !important;
  }
  h2,
  .nmx-h2 {
    font-size: rem-calc(21px) !important;
    line-height: rem-calc(30px) !important;
  }
  h3,
  .nmx-h3 {
    font-size: rem-calc(18px) !important;
    line-height: rem-calc(26px) !important;
  }
  h4,
  .nmx-h4 {
    font-size: rem-calc(16px) !important;
    line-height: rem-calc(24px) !important;
  }
  .nmx-base ul {
    padding-left: 1rem !important;
  }

  sup {
    font-size: rem-calc(12px) !important;
    line-height: rem-calc(15px) !important;
    &::before {
      content: ' \0028' !important;
    }
    &::after {
      content: ' \0029' !important;
    }
  }

  // fix nested columns
  // [class*="nmx-col-"] {
  //   margin-left: 0 !important;
  //   margin-right: 0 !important;
  //   padding-left: 0 !important;
  //   padding-right: 0 !important;
  // }

  // style specific elements

  .disclaimer,
  .footer, // legacy, will have to come back and address legacy styles once nmx-template 1.0.x is installed in all of the repos.
  .nmx-footer {
    // most of this is for legacy
    max-width: 90rem !important;
    height: auto !important;
    margin: 0 auto !important;
    & * {
      font-size: rem-calc(12px) !important; // retain for new styles
      line-height: rem-calc(15px) !important; // retain for new styles
    }
    &--disclosures,
    &--logo,
    &--contents {
      margin-bottom: 1rem !important;
    }

    &--disclosures {
      border-bottom: none !important;
      margin-bottom: 0 !important;
      #footer--disclosures-collapse-toggle,
      .footer--toggle-label {
        display: none !important;
      }
    }
    &--logo {
      .img {
        width: rem-calc(200px) !important;
        margin-bottom: rem-calc(-10px) !important;
      }
    }
    &--contents {
      .footer--menu,
      .footer--social,
      .footer--stats > a {
        display: none !important;
      }
    }
    .footnote-item small {
      display: block !important;
    }

    // newer styles listed here, will confirm before removing legacy styles above
    &__disclaimer,
    &__disclaimer-content {
      padding: 0.5rem 0 !important;
    }
    &-logo {
      display: none !important;
      &-container {
        width: rem-calc(200px) !important;
        padding: 0.5rem 0 !important;
      }
    }
    &-social__links-heading,
    &-social__links-container {
      display: none !important;
    }
    &-fafa {
      &__container {
        padding-bottom: 0.5rem !important;
        .nmx-link {
          display: none !important;
        }
      }
    }
  }
}
