.nmx-search-rep-card {
  padding-top: 1rem;
  padding-bottom: 2rem;

  .fr {
    &-title,
    &-name {
      font-weight: $weight-light;
      letter-spacing: rem-calc(1px);
    }
    // &-professional-designations {

    // }

    &-professional-designation {
      display: inline;
    }

    &-address,
    &-contact-links,
    &-phone {
      display: block;
      margin-bottom: 1rem;
      white-space: pre-wrap;
    }

    &-contact {
      margin-top: 2rem;

      h5 {
        font-weight: $weight-light;
        text-transform: uppercase;
        &.block {
          margin-top: 1rem;
        }
      }

      &-cta {
        &::after {
          position: relative;
          top: rem-calc(2px);
          left: 5%;
          display: inline-block;
          width: rem-calc(15px);
          height: rem-calc(15px);
          background-image: url('/template/assets/<%=templateVersion%>/images/icons/utility/chevron-right.svg'); // TODO: encode svg
          background-repeat: no-repeat;
          content: ' ';
          filter: invert(100%) sepia(2%) saturate(7448%) hue-rotate(351deg)
            brightness(113%) contrast(111%); // color to #FFFFFF $color-neutral-white
        }
      }

      &-more-info {
        padding-right: 0;
        @include breakpoint(medium) {
          padding-right: 2rem;
        }
      }

      &-service {
        margin-top: 2rem;
        // margin-left: 0;
        margin-right: 0;
        @include breakpoint(medium) {
          // margin-left: 2rem;
          padding-left: 2rem;
          border-left: 1px solid $color-system-cta-yellow;
          margin-top: 0;
        }
      }
    }
  }
}
