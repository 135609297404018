.nm-expandable-toast {
  position: fixed;
  z-index: $z-index-200-toast;
  bottom: 5%;
  max-height: 85%;
  margin: 0;
  font-family: sans-serif;
  line-height: 1.15;

  &--hidden {
    display: none;
  }
  &__close-button {
    position: relative;
    top: 0; // overrides legacy mixins
    left: 0; // overrides legacy mixins
    width: rem-calc(22px);
    height: rem-calc(23px);
    align-self: flex-start;
    padding: rem-calc(1px 3px 0);
    margin-top: rem-calc(-23px);
    margin-left: rem-calc(16px);
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: rem-calc(2px);
    color: $color-neutral-gray-23;
    font-size: rem-calc(16px);
    @media all and (-ms-high-contrast: none) {
      // TODO: configure IE mixins
      top: rem-calc(-20px);
    }

    @include breakpoint(medium) {
      position: absolute;
      margin-left: rem-calc(50px);
      cursor: pointer;
      font-weight: $weight-semibold;

      @media all and (-ms-high-contrast: none) {
        // TODO: configure IE mixins
        top: rem-calc(-26px);
      }
    }
  }
  &__box {
    &#{&} {
      width: rem-calc(280px);
      max-height: 100%;
      margin-left: rem-calc(16px);
      background-color: $color-neutral-gray-23;
      border-radius: rem-calc(7px);
      color: $color-neutral-white;
      overflow-y: auto;
      @include breakpoint(medium) {
        width: rem-calc(325px);
        margin-left: rem-calc(50px);
        font-size: rem-calc(20px);
      }
    }
  }
  &__content-container {
    &#{&} {
      padding: rem-calc(10px 12px);
      @include breakpoint(medium) {
        display: flex;
        width: 100%;
        height: rem-calc(60px);
        flex-direction: column;
        justify-content: space-between;
        padding: rem-calc(0 0 5px);
      }
    }
  }
  &__toggle-button {
    &#{&} {
      width: auto;
      height: rem-calc(30px);
      background-color: transparent;
      color: $color-neutral-white;
      font-size: rem-calc(16px);
      text-transform: none;
      @include breakpoint(medium) {
        display: block;
        width: 100%;
        height: rem-calc(45px);
        flex: 1 0 auto;
        padding: 0;
        cursor: pointer;
      }
    }
  }
  &__heading {
    &#{&} {
      font-size: rem-calc(12px);
      font-weight: $weight-regular;
      line-height: rem-calc(18px);
      @include breakpoint(medium) {
        width: 100%;
        margin: 0;
        font-size: rem-calc(15px);
        line-height: rem-calc(22px);
      }
      &-text {
        display: inline-block;
      }
      &--is-expanded {
        padding-bottom: rem-calc(8px);
        border-bottom: 1px solid $color-neutral-white;
        @include breakpoint(medium) {
          padding-bottom: rem-calc(15px);
        }
        .nm-expandable-toast__toggle-button-icon-container {
          top: rem-calc(-20px);
          @include breakpoint(medium) {
            top: rem-calc(-2px);
          }
        }
      }
    }
  }
  &__toggle-button-icon-container {
    // TODO: can be nested
    &#{&} {
      position: relative;
      top: rem-calc(2px);
      display: inline-block;
      width: rem-calc(15px);
      height: rem-calc(9px);
      margin-left: rem-calc(4px);
      @include breakpoint(medium) {
        position: relative;
        display: inline-flex;
        width: rem-calc(15px);
        height: rem-calc(22px);
        flex: 0 0 auto;
        align-items: center;
        justify-content: center;
        margin-left: rem-calc(7px);
      }
    }
  }
  &__toggle-button-icon {
    display: block;
    width: rem-calc(13px);
    height: rem-calc(13px);
    padding: 0;
    margin: 0;
    background-image: url('/template/assets/<%=templateVersion%>/images/icons/utility/chevron-down.svg'); // TODO: encode svg
    background-repeat: no-repeat;
    filter: invert(91%) sepia(26%) saturate(6548%) hue-rotate(372deg)
      brightness(104%) contrast(101%); // $color-system-cta-yellow
    // TODO: can be nested
    font-size: 27px;

    @include breakpoint(medium) {
      display: flex;
      width: rem-calc(20px);
      height: rem-calc(15px);
      color: $color-system-cta-yellow;
      transform: rotate(0deg);
    }
    &--inverted {
      position: relative;
      top: rem-calc(15px);
      transform: rotate(180deg);
      @include breakpoint(medium) {
        top: 0;
      }
    }
  }
  &__toggle-content {
    &#{&} {
      padding-right: 0;
      overflow-y: hidden;
      transition: height 0.3s ease;
      @include breakpoint(medium) {
        height: 0;
        padding: 0;
      }
    }
  }
  &__text {
    &#{&} {
      padding: rem-calc(0 16px);
      margin: rem-calc(16px 0 5px);
      color: $color-neutral-white;
      font-size: rem-calc(13px);
      font-weight: $weight-light;
      line-height: rem-calc(22px);
      @include breakpoint(medium) {
        margin: rem-calc(16px 0 0);
        font-size: rem-calc(14px);
        line-height: rem-calc(22px);
      }
    }
  }
  &__text-block {
    &#{&} {
      display: block;
      margin-bottom: rem-calc(10px);
    }
  }
  &__text-close-button {
    &#{&} {
      height: rem-calc(50px);
      padding: 0 1rem;
      margin-top: rem-calc(-15px);
      background-color: transparent;
      color: $color-neutral-white;
      font-size: rem-calc(11px);
      font-weight: $weight-regular;
      letter-spacing: rem-calc(3px);
      text-transform: uppercase;
      @include breakpoint(medium) {
        display: block;
        height: rem-calc(45px);
        margin-top: rem-calc(-13px);
        cursor: pointer;
        font-size: rem-calc(13px);
      }
    }
  }
  &__text-close-button,
  &__toggle-button,
  &__close-button {
    border-color: transparent;
  }
}
