// namespacing all new homepage sections with "-home" for modularity
.nmx-section-accolades {
  background-color: $color-section-background-blue;
  color: $color-neutral-white;
  text-align: center;

  .leading-content {
    margin-bottom: rem-calc(20px);
    @include breakpoint(medium) {
      margin-bottom: rem-calc(40px);
    }
  }

  &-item {
    &:not(:last-child) {
      margin-bottom: rem-calc(40px);
      @include breakpoint(medium) {
        margin-bottom: 0;
      }
    }
    @include breakpoint(medium) {
      padding-right: rem-calc(5px);
      padding-left: rem-calc(5px);
    }

    .rating {
      margin: 1rem 0;
      @include breakpoint(small) {
        margin: 1rem rem-calc(40px) 0;
      }
      @include breakpoint(medium) {
        margin: 1rem rem-calc(20px);
      }
      // &-no-blockquote {
      // specific styles for non-blockquote accolade
      // }
      &-quantifier {
        display: block;
        color: $color-blue-on-blue-heading-accent;
        font-weight: $weight-light;
        @extend .nmx-jumbo-type;

        &::after {
          display: block;
          width: rem-calc(60px);
          padding-bottom: rem-calc(15px);
          border-bottom: rem-calc(4px) solid $color-system-cta-yellow;
          margin: 0 auto rem-calc(15px);
          content: '';
        }
      }

      &-description {
        color: $color-neutral-white;
      }

      &.top-five {
        .rating-quantifier {
          text-transform: uppercase;
        }
      }
    }
  }
}
