// z-index reference notes // TODO: extrude from/to settings/z-index.scss
// #nmx-skip-nav, #nmx-skip-nav.nmx-nav-link = $z-index-360-global-nav-skip
// .nmx-nav-primary = $z-index-330-global-nav-primary
// utility nav, main nav = $z-index-320-global-nav-main
// .nmx-mobile-nav-overlay = $z-index-310-global-nav-overlay
// .nmx-search = $z-index-400-search

// constants
$mobile-nav-width: 320px;
$desktop-layout-shift-breakpoint: px-to-em(1124px);

#nmx-skip-nav {
  position: fixed;
  z-index: $z-index-360-global-nav-skip;
  top: 0;
  left: rem-calc(-9999px);
  opacity: 0;
  &.is-active {
    left: 0;
    opacity: 1;
  }
  .nmx-nav-link {
    position: relative;
    top: 0;

    left: 0;
    display: block;
    width: rem-calc(200px);
    padding: 1rem;
    background-color: $color-neutral-white;
    color: $color-section-background-blue;
    font-size: 12px;
    font-weight: $weight-medium;
    line-height: 18px;
    text-align: center;
    text-transform: uppercase;

    &:focus {
      @include breakpoint(large) {
        z-index: $z-index-360-global-nav-skip; // TODO: build this (uninterrupted visibile focus ring) into focus manager
      }
    }
  }
}

#preloaded-svgs,
.preloaded-svgs {
  // .preloaded-svgs from nmx-static
  // moved to just inside closing body tag
  position: absolute;
  overflow: hidden;
  width: 0;
  height: 0;
}

// ============================================================
// nmx-header
// ============================================================
.nmx-header {
  // is-overlayed only on desktop (with or without full header nav)
  @include breakpoint(large) {
    &.is-overlayed {
      height: auto;
      #nmx-mobile-nav-container {
        // using id for higher specificity
        position: absolute;
        max-width: $absolute-canvas-max-width;
        height: rem-calc(115px);
        padding-right: 0;
        padding-left: 0;
        background-color: transparent;
        &::before {
          position: absolute;
          z-index: $z-index-320-global-nav-main;
          top: 0;
          right: 0;
          left: 0;
          height: rem-calc(115px);
          border-bottom: 1px solid $color-neutral-white;
          background: linear-gradient(
            rgba($color-neutral-black, 0.8),
            rgba($color-neutral-black, 0.2)
          );
          content: '';
        }
      }
      .nmx-header-logo {
        // TODO: use dynamic template asset path
        @include breakpoint(large) {
          // TODO: what is this doing here? test redundancy
          z-index: $z-index-320-global-nav-main;
          background-image: url('/template/assets/<%=templateVersion%>/images/logo-horizontal-white.svg'); // TODO: encode svg // TODO: needs dynamic asset path treatment
          &-container {
            z-index: $z-index-321-global-nav-logo;
            background-color: transparent;
          }
        }
      }
      .nmx-nav-primary {
        .nmx-nav-link {
          &-primary {
            color: $color-neutral-white;
            &:hover,
            &:focus {
              color: $color-system-cta-yellow;
            }
            &-container {
              background-color: transparent;
            }
          }
        }
      }
      #nmx-nav-link-search-container,
      #nmx-nav-link-utility-search {
        background-color: transparent;
        .nmx-icon-search {
          .icon-fill {
            fill: $color-neutral-white;
          }
        }
        &:hover,
        &:focus {
          .nmx-icon-search {
            .icon-fill {
              fill: $color-system-cta-yellow;
            }
          }
        }
      }
      &.nmx-informational-banner-active {
        margin-bottom: rem-calc(-121px);
        #nmx-mobile-nav-container {
          position: relative;
        }
      }
    }
  }
  // end is-overlayed

  // is-logo-only on desktop and mobile
  &.is-logo-only {
    #nmx-nav-primary,
    #nav-utility-container,
    #nmx-search-console,
    #nav-toggle,
    .nmx-login-button,
    #nmx-client-login {
      display: none;
    }

    #nmx-mobile-nav-container {
      &::before {
        display: none;
      }
    }
    @include breakpoint($max: large) {
      border-bottom: none;
      #nmx-mobile-nav-container {
        left: 0;
        overflow-y: auto;
      }
      #header-logo {
        left: 0;
        margin-left: 1rem;
      }
    }

    // has-logo-eyebrow header on desktop and mobile
    &.has-logo-eyebrow {
      height: auto;

      #nmx-mobile-nav-container {
        position: relative;
        // TODO: can consolidate default styles with is-logo-only
        // using id for higher specificity
        top: 0;
        height: rem-calc(
          50px
        ) !important; // TODO: if has-logo-eyebrow mode, don't set #nmx-mobile-nav-container height via javascript

        .nmx-nav-primary-container {
          height: rem-calc(50px);
          margin-top: 0;
          .nmx-header-logo-container {
            height: rem-calc(50px);
            .nmx-header-logo {
              margin: rem-calc(-5px) auto 0;
              @include breakpoint($max: small) {
                margin-top: rem-calc(5px);
              }
            }
          }
        }
      }

      &.has-logo-eyebrow-large {
        #nmx-mobile-nav-container {
          @include breakpoint(large) {
            height: rem-calc(
              100px
            ) !important; // TODO: if has-logo-eyebrow mode, don't set #nmx-mobile-nav-container height via javascript
            .nmx-header-logo-container {
              width: auto;
              height: rem-calc(100px);
              .nmx-header-logo {
                width: rem-calc(500px);
                height: rem-calc(60px);
                margin-top: 0;
              }
            }
          }
        }
      }
    }
    // end has-logo-eyebrow

    &.is-overlayed {
      // TODO: can consolidate with is-overlayed for full nav
      @include breakpoint($max: large) {
        #nmx-mobile-nav-container {
          position: relative;
          // using id for higher specificity
          top: 0;
          height: rem-calc(50px) !important;
          .nmx-nav-primary-container {
            height: rem-calc(50px);
            margin-top: 0;
            .nmx-header-logo {
              margin: rem-calc(-5px) auto 0;
              @include breakpoint($max: small) {
                margin-top: rem-calc(5px);
              }
            }
          }
        }
      }
    }

    // auto-remove bottom border on logo-only
    border-bottom: none;
  }
  // end is-logo-only

  // show utiltiy nav blue bg extension
  #nmx-nav-link-fafa-link-container {
    &::after {
      @include breakpoint(large) {
        position: fixed;
        z-index: -1;
        top: 0;
        right: 0;
        display: block;
        width: 20%;
        height: rem-calc(40px);
        background-color: $color-primary-nm-blue;
        content: '';
        @media all and (-ms-high-contrast: none) {
          // ie11
          display: none;
        }
      }
      /** to prevent blue bg extension from getting detached from utility nav, introduce reattachment at extremely large viewport widths  */
      @include breakpoint(2300) {
        position: absolute;
        left: 95%;
        right: unset;
        width: 50%;
      }
    }
  }

  height: rem-calc(50px);
  background-color: $color-neutral-white;
  @include breakpoint(large) {
    height: rem-calc(116px);
    &.nmx-informational-banner-active {
      height: rem-calc(170px); // TODO: this whole infoBanner module needs to be more dynamic/fluid
    }
  }

  &.without-border {
    border-bottom: none;
  }

  &-logo {
    position: relative;
    // TODO: most of these mobile styles will be consolidated once
    top: 0;
    left: rem-calc(-15px);
    display: flex;
    width: 100%;
    max-width: rem-calc(180px);
    height: rem-calc(30px);
    align-self: center;
    margin: 0 auto;
    background-image: url('/template/assets/<%=templateVersion%>/images/logo-horizontal-navy.svg'); // TODO: encode svg // TODO: needs dynamic asset path treatment
    background-position-x: center;
    background-repeat: no-repeat;
    background-size: contain;
    text-indent: rem-calc(-9999px);
    @include breakpoint(small) {
      max-width: rem-calc(250px);
    }
    @include breakpoint(large) {
      left: 0;
      width: rem-calc(250px);
      max-width: unset;
      margin: 0;
      background-position-x: 0;
      background-size: cover;
    }
    @include breakpoint(large1200) {
      margin-top: rem-calc(-15px);
    }

    &-container {
      &#{&} {
        position: fixed;
        z-index: $z-index-310-global-nav-overlay;
        display: flex;
        width: 100%;
        height: rem-calc(50px);
        background-color: $color-neutral-white;

        @include breakpoint($max: large) {
          @include shadow(small);
        }

        //  if nmx-informational-banner-active mobile, set from JS
        @include breakpoint(large) {
          position: absolute;
          z-index: $z-index-321-global-nav-logo;
          left: 0;
          display: flex;
          width: rem-calc(250px);
          max-width: unset;
          height: rem-calc(40px);
          padding: 0;
          margin-left: rem-calc(20px);
          .nmx-header.nmx-informational-banner-active & {
            // if nmx-informational-banner active
            top: 0;
          }
        }
        @include breakpoint(large1200) {
          position: relative;
          display: inline-flex;
          height: rem-calc(75px);
          margin-left: rem-calc(28px);
        }
      }
    }
  }
  .nmx-container {
    &__header-parent {
      max-width: none;
      padding: 0;
      margin: 0;
      background-color: $color-neutral-white;
      @include shadow(small);
      
      @include breakpoint(large) {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: $z-index-330-global-nav-primary;
      }
      &-inner {
        max-width: $header-max-width;
        margin: auto;
        @include breakpoint(large) {
          padding: 0 0.75rem; // initial
        }
      }
    }
  }
  // if nmx-informational-banner active // .nmx-header
  // &.nmx-informational-banner-active {
  //   @include breakpoint(large) {
  //     height: auto;
  //   }
  // }
}

// ============================================================
// nmx-nav-primary
// ============================================================

.nmx-nav-primary {
  position: absolute;
  z-index: $z-index-330-global-nav-primary;
  width: 100%;
  border-top: 1px solid $color-neutral-gray-59;
  margin-top: rem-calc(82px);

  @include breakpoint(large) {
    position: absolute;
    top: rem-calc(40px);
    display: inline-block;
    width: auto;
    border-top: none;
    margin-top: 0;
  }

  @include breakpoint(large1200) {
    top: 0;
  }

  &-container {
    height: rem-calc(82px);
    padding: 0;
    text-align: center;
    @include breakpoint(large) {
      max-width: max-content;
      height: rem-calc(40px);
      padding: inherit; // TODO: test 'inherit' in IE
      text-align: left;
    }
    @include breakpoint(large1200) {
      position: relative;
      max-width: unset;
      height: rem-calc(75px);
      margin-top: rem-calc(40px);
    }
  }

  .nmx-nav-link-primary {
    // TODO: look at organizing this better with .nmx-nav-link
    position: relative;
    height: 100%;
    border-bottom: 1px solid $color-neutral-gray-59;
    color: $color-section-background-blue;
    font-weight: $weight-medium;
    letter-spacing: rem-calc(3px);
    text-transform: uppercase;
    &::after {
      position: absolute;
      right: 5%;
      width: rem-calc(15px);
      height: rem-calc(15px);
      background-image: url('/template/assets/<%=templateVersion%>/images/icons/utility/chevron-right.svg'); // TODO: encode svg // TODO: needs dynamic asset path treatment
      background-repeat: no-repeat;
      background-size: rem-calc(10px);
      content: ' ';
      filter: invert(25%) sepia(96%) saturate(7900%) hue-rotate(195deg)
        brightness(100%) contrast(84%); // color to #1570bc ($color-system-cta-blue)
      @include breakpoint(large) {
        display: none;
        background: none;
        filter: none;
      }
    }
    &:hover {
      text-decoration: none;
    }
    @include breakpoint(large) {
      z-index: $z-index-330-global-nav-primary;
      display: flex;
      align-items: center;
      border: none;
      margin: rem-calc(0 15px);
      &#nmx-nav-link-primary-who-we-are {
        margin-left: 0;
      }
    }
    @include breakpoint(large1200) {
      &#nmx-nav-link-primary-who-we-are {
        margin-left: rem-calc(15px);
      }
    }
    &-container {
      height: rem-calc(60px);
      text-align: left;
      @include breakpoint(large) {
        display: inline-block;
        height: rem-calc(75px);
      }
    }

    &.is-active,
    &.has-focus,
    &.has-hover,
    // &[data-subnav-active=true],
    &.subnav-is-active {
      color: $color-system-cta-yellow;
      &::after {
        @include breakpoint(large) {
          position: absolute;
          bottom: rem-calc(-1px);
          left: 0;
          display: block;
          width: 100%;
          height: rem-calc(4px);
          margin-top: rem-calc(13px);
          background: $color-system-cta-yellow;
          content: '';
        }
      }
    }

    &-sub, // subnav level1
    &-sub-level2 {
      // subnav level2
      display: block;
      padding: 0;
      color: $color-section-background-blue;
      font-weight: $weight-medium;
      letter-spacing: rem-calc(3px);
      text-transform: uppercase;
      // TODO: these could possibly be eventually built into some global menu defaults
      &:focus,
      &:hover,
      &:active {
        color: $color-section-background-blue;
      }
      @include breakpoint(large) {
        padding: rem-calc(10px 16px);
        color: $color-neutral-gray-29;
      }
    }
    &-sub {
      &.has-subnav-level2 {
        &::after {
          position: absolute;
          right: 5%;
          width: rem-calc(15px);
          height: rem-calc(15px);
          background-image: url('/template/assets/<%=templateVersion%>/images/icons/utility/chevron-right.svg'); // TODO: encode svg // TODO: needs dynamic asset path treatment
          background-repeat: no-repeat;
          content: ' ';
          filter: invert(25%) sepia(96%) saturate(7900%) hue-rotate(195deg)
            brightness(100%) contrast(84%); // color to #1570bc ($color-system-cta-blue)
          @include breakpoint(large) {
            display: none;
          }
        }
      }
    }
    &-sub-level2 {
      display: inline-flex;
      align-items: center;
      text-align: right;
      &.has-icon {
        height: rem-calc(42px);
        padding: 1rem rem-calc(50px) 1rem rem-calc(21px);
        padding-right: rem-calc(50px);
        border: none !important; // TODO: figure out specificity and remove this hack
        margin-right: rem-calc(36px);
        margin-left: rem-calc(16px);
        background-color: $color-neutral-gray-95;
        text-align: left;
        @include breakpoint(large) {
          height: rem-calc(30px);
          margin-right: 0;
          margin-left: rem-calc(20px);
          text-align: right;
        }
        @supports (background: -webkit-named-image(i)) {
          /** Safari fix */
          @include breakpoint(large) {
            margin-right: rem-calc(36px); // safari desktop
          }
        }
        &.nmx-subnav-level2-item-life-insurance {
          .nmx-icon {
            @include breakpoint(large) {
              right: rem-calc(10px);
              @supports (background: -webkit-named-image(i)) {
                right: 0;// safari desktop
              }
            }
          }
        }
      }
      @include breakpoint(large) {
        padding: rem-calc(5px 10px);
        margin: rem-calc(3px 0 3px 30px);
        &.nmx-nav-link-primary-sub-level2 {
          // nested for higher specificity // TODO: can be cleaned up
          font-weight: $weight-regular;
          white-space: nowrap;
        }
      }
      .nmx-icon {
        position: absolute;
        right: 0;
        width: rem-calc(55px);
        height: rem-calc(55px);
        margin-right: rem-calc(10px);
        margin-left: rem-calc(10px);

        @include breakpoint(large) {
          width: rem-calc(50px);
          height: rem-calc(50px);
          margin-top: 0; // safari fix
          @media all and (-ms-high-contrast: none) {
            right: 4rem;
            // ie11
            margin-top: rem-calc(-15px);
          }
        }
      }
    }
  }

  .nmx-subnav,
  .nmx-subnav-level2 {
    position: absolute;
    z-index: $z-index-330-global-nav-primary;
    display: block;
    width: 100%;
    margin-left: -100%;
    animation: none;
    opacity: 1;
    transition: margin-left 0.15s ease-out;
    @include breakpoint($max: large) {
      top: 0;
    }

    & > .nmx-nav-link-container {
      @include breakpoint($max: large) {
        height: rem-calc(60px);
        background-color: $color-neutral-white;
        .nmx-nav-link {
          &:not(.nmx-nav-link-primary-sub--first):not(.nmx-nav-link-primary-sub-level2--first):not(.nmx-nav-link-primary-sub--last):not(.nmx-nav-link-primary-sub-level2--last) {
            border-bottom: 1px solid $color-neutral-gray-59;
          }
        }
      }
      @include breakpoint(large) {
        width: max-content; // safari menu width fix
      }
    }

    &.is-active {
      margin-left: 0;
    }
  }

  .nmx-subnav {
    @include breakpoint(large) {
      position: absolute;
      display: block;
      width: auto;
      padding: 1rem;
      margin-top: rem-calc(2px);
      margin-left: 0;

      background-color: $color-neutral-white;
      opacity: 0;
      transform: scale(0.1) translateY(-10%);
      transform-origin: 20% -10%;
      transition: transform 0.15s ease, opacity 0.15s ease;
      @media all and (-ms-high-contrast: none) {
        // IE11 set base collapsed state
        max-width: rem-calc(420px);
        height: 0 !important;
        padding: 0;
      }
      @include shadow(large);

      &.is-active {
        @media all and (-ms-high-contrast: none) {
          // IE11 visible nav state (no animation for you, IE11)
          height: auto !important;
          padding: 1rem;
        }

        opacity: 1;
        transform: scale(1) translateY(0);
      }
    }

    @include is-safari {
      @include breakpoint(large) {
        // Safari animation and transitions failing // (sorry, no animation for you either, Safari (for now))
        // TODO: revisit (JavaScript) menu animation in Styled Components when Header is converted over to nmx-template 2.0.x
        opacity: 0;
        transform: scale(0.1) translateY(-10%);
        transition: none;
        .is-active {
          opacity: 1;
          transform: scale(1) translateY(0);
        }
      }
    }

    // right alignment between medium and large for far-right (Life and Money) subnav
    // &-life-and-money {
    //   @include breakpoint(769, 820) {
    //     right: 0;
    //   }
    // }
    @include breakpoint(large) {
      margin-top: rem-calc(1px);
    }
  }

  .nmx-subnav-level2 {
    @include breakpoint(large) {
      position: relative;
      padding-right: rem-calc(30px);
      margin: rem-calc(3px 0);
    }
    .nmx-nav-link-mobile-subnav-overview-container {
      @include breakpoint(large) {
        display: none; // remove duplicate subnav-level2 mobile overview on desktop viewport widths using mobile/touchscreen devices // TODO: hook up "nmx-nav-link-mobile-subnav-level2-overview" and "nmx-nav-link-mobile-subnav-overview-container"
      }
    }
  }
  @supports (background: -webkit-named-image(i)) {
    /** Safari fix */
    .nmx-subnav-level2 {
      @include breakpoint(large) {
        padding-right: 0; // safari subnav-level2 spacing
      }
    }
  }
}

// ============================================================
// nmx-nav-link
// ============================================================

.nmx-nav-link {
  &#{&} {
    height: auto;
    font-size: rem-calc(12px);
    font-weight: $weight-medium;
    line-height: rem-calc(18px);
    @include breakpoint(large) {
      height: 100%;
      font-size: rem-calc(11px);
      line-height: rem-calc(17px);
    }
    @include breakpoint(xlarge) {
      font-size: rem-calc(13px);
      line-height: rem-calc(20px);
    }
  }
  &-container {
    display: flex;
    align-items: center;
    background-color: $color-neutral-white;
    @include breakpoint(large) {
      display: inherit; // TODO: test 'inherit' in IE
      align-items: normal;
      padding: 0;
      border-bottom: none;
    }

    .nmx-nav-link {
      width: 100%;
      // TODO: possibly consolidate with .nmx-nav-link-primary without sabotaging code bloat/sleek.
      // TODO: remove after testing

      height: 100%; // for specificity

      @include breakpoint($max: large) {
        display: flex;
        align-items: center;
        margin: 0 1rem;
      }

      @include breakpoint(large) {
        width: auto;
        height: 100%;
      }
    }
  }
  &-mobile-back,
  &-mobile-level2-back {
    max-width: unset;
    border-color: $color-neutral-white;
    color: $color-system-cta-blue;
    font-weight: $weight-medium;
    text-transform: uppercase;
    // TODO: these could possibly be eventually built into some global menu defaults
    &:focus,
    &:hover,
    &:active {
      border-color: $color-neutral-white;
    }

    &::after {
      position: absolute;
      left: 1rem;
      width: rem-calc(30px);
      height: rem-calc(18px);
      background-repeat: no-repeat;
      color: $color-system-cta-blue;
      content: '\2190';
      font-size: rem-calc(20px);
    }
    &-container {
      border-bottom: none;
      .nmx-nav-mobile-subnav-bg {
        position: absolute;
        z-index: -1;
        // top: rem-calc(60px);
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background-color: $color-blue-lazyload-bg;
        opacity: 0.95;
      }
      @include breakpoint(large) {
        display: none;
      }
    }
  }
  &-mobile-back {
    &#{&} {
      padding-left: 2rem;
    }
  }
  &-mobile-level2-back {
    &#{&} {
      padding-left: 2rem;
    }
  }
  &-mobile-subnav-overview {
    &#{&} {
      color: $color-section-background-blue;
      letter-spacing: rem-calc(3px);
      text-transform: uppercase;
    }

    @include breakpoint(large) {
      display: none;
      &.is-active-desktop {
        display: block;
        padding: 1rem;
        color: $color-neutral-gray-29;
      }
      &-subnav-level2 {
        margin-left: 1.875rem;
        font-weight: $weight-regular;
      }
      &:not(.nmx-no-touch) & {
        // for mobile devices > 769px (i.e. iPhone X landscape)
        display: block;
      }
    }
  }
  &-mobile-primary-home {
    &#{&} {
      border-bottom: 1px solid $color-neutral-gray-59;
      background-color: $color-neutral-white;
      color: $color-section-background-blue;
      text-transform: uppercase;
      letter-spacing: rem-calc(3px);
      // TODO: these could possibly be eventually built into some global menu defaults
      &:focus,
      &:hover,
      &:active {
        border-color: $color-neutral-white;
      }
    }
    &-container {
      &#{&} {
        @include breakpoint(large) {
          display: none;
        }
      }
    }
  }
}

// ============================================================
// nmx-nav-utility
// ============================================================

.nmx-nav-utility {
  z-index: $z-index-320-global-nav-main;
  width: 100%;
  .nmx-nav-link-utility {
    color: $color-neutral-white;
    font-size: rem-calc(15px);
    line-height: rem-calc(22px);
    font-weight: $weight-regular;
    letter-spacing: rem-calc(1px);
    @include breakpoint(large) {
      align-self: center;
      padding: rem-calc(10px 15px 8px);
    }
    &:hover {
      color: $color-neutral-white;
      text-decoration: underline;
    }
    &-container {
      display: flex;
      height: rem-calc(60px);
      align-items: center;
      border-bottom: 1px solid $color-neutral-gray-59;
      background-color: $color-section-background-blue; // mobile?
      // utility nav delimiter
      &:nth-child(-n + 1) {
        &::after {
          @include breakpoint(large) {
            position: relative;
            color: $color-neutral-white;
            content: '|';
            font-weight: $weight-thin;
          }
        }
      }
      @include breakpoint(large) {
        display: inline-flex;
        height: rem-calc(40px);
        padding: 0;
        border-bottom: none;
      }
      &-mobile-button {
        @include breakpoint($max: large) {
          border: none;
          margin: 1rem 0;
          background: none;
        }
      }
      .nmx-nav-link {
        @include breakpoint($max: large) {
          display: flex;
          width: 100%;
          height: 100%;
          align-items: center;
          padding: 0 1rem;
        }
      }
    }
  }
  & > ul {
    display: block;
    @include breakpoint(large) {
      display: flex;
      justify-content: flex-end;
    }
  }
  &-container {
    display: flex;
    width: 100%;
    padding: 0;
    margin-top: rem-calc(360px);
    @include breakpoint(large) {
      position: relative;
      width: auto;
      padding-right: 0;
      margin-top: rem-calc(-40px);
      float: right;

      // ie flexbox hack/fix
      @media all and (-ms-high-contrast: none) {
        right: 0;
      }
    }
    @include breakpoint(large1200) {
      margin-top: rem-calc(-115px);
    }
    @include breakpoint(xlarge) {
      padding: 0 1rem;
    }
  }
}

#nmx-nav-link-fafa-link-container {
  @include breakpoint(large) {
    position: relative;
    right: rem-calc(-72px);
  }
}

#nmx-nav-link-utility-fafa {
  // TODO: should technically be a nmx-button--primary (not secondary)
  @include breakpoint($max: large) {
    max-width: unset;
    justify-content: center;
    margin: 1rem;
  }
  @include breakpoint(large) {
    // nmx-button--primary styles
    display: inline-flex;
    min-width: auto;
    max-width: unset;
    height: rem-calc(30px);
    min-height: unset;
    padding: rem-calc(8px 10px 8px);
    border: rem-calc(1px) solid $color-system-cta-yellow;
    background-clip: padding-box;
    background-color: rgb(255, 184, 28);
    border-radius: rem-calc(2px);
    font-weight: $weight-regular;
    font-size: rem-calc(15px);
    line-height: rem-calc(22px);
    letter-spacing: rem-calc(1px);
    text-decoration: none;
    color: $color-neutral-black;
    -webkit-tap-highlight-color: transparent;
    align-items: center;
    justify-content: center;
    transition: background-color 100ms ease-out 0s, box-shadow 100ms ease-out 0s, color 100ms ease-out 0s, border 100ms ease-out 0s;
    user-select: none;
    cursor: pointer;

    &:hover,
    &.is-active {
      text-decoration: none;
      border: 1px solid $color-system-cta-hover-yellow;
      background-color: $color-system-cta-hover-yellow;
      box-shadow: 0 2px 8px 0 rgba($color-neutral-black, 0.1);
    }
  }
}

#nmx-nav-link-claims-link-container {
  @include breakpoint(large) {
    position: relative;
    right: rem-calc(205px);
  }
  &::after {
    @include breakpoint(large) {
      content: '';
      display: inline-block;
      width: rem-calc(10px); // this causes a defect locally, but not in the destination repo
      height: rem-calc(40px); // this causes a defect locally, but not in the destination repo
      background-color: $color-primary-nm-blue;
    }
  }
}

#nmx-nav-link-utility-claims {
  // TODO: should technically be a nmx-button--secondary (not tertiary)
  @include breakpoint($max: large) {
    max-width: unset;
    justify-content: center;
    margin: 1rem;
    color: $color-system-cta-blue;
  }
}

// ============================================================
// nmx-login-open-button
// ============================================================

#nmx-nav-link-login-button-container {
  position: absolute;
  top: -50px;
  @include breakpoint(large) {
    position: static;
    top: 0;
  }
}

.nmx-logout-iframe-container {
  display: none;
}

.nm-login-button {
  // TODO: when markup is finally corrected, kill these styles as needed.
  &#{&} {
    position: fixed;
    z-index: $z-index-320-global-nav-main;
    top: 0;
    right: 0;
    width: rem-calc(80px);
    height: rem-calc(50px);
    @include breakpoint(large) {
      position: relative;
      top: auto;
      right: auto;
      width: auto;
      height: rem-calc(40px);
    }
  }
}

// TODO: when markup is finally corrected, utilize these styles as much as possible
#nmx-login-open-button {
  display: inline-block;
  width: rem-calc(80px);
  // id for higher specificity
  height: 100%;
  border: none;
  margin: 0 auto;
  background-color: $color-section-background-blue;
  border-radius: 0;
  color: $color-neutral-white;
  font-size: rem-calc(15px);
  line-height: rem-calc(22px);
  letter-spacing: rem-calc(1px);
  cursor: pointer;
  @include breakpoint(large) {
    width: auto;
    padding-top: rem-calc(9px);
    margin: 0;
  }
  @include breakpoint(xlarge) {
    padding-top: rem-calc(8px);
  }
  .nmx-icon {
    display: none;
    margin-right: rem-calc(7px);
    float: left;
    @include breakpoint(large) {
      display: block;
      margin-top: rem-calc(2px);
    }
    .icon-fill {
      fill: $color-blue-on-blue-heading-accent;
    }
  }
  &:hover,
  &:focus {
    background-color: $color-system-cta-hover-blue;
    text-decoration: underline;
    .nmx-icon {
      .icon-fill {
        fill: $color-neutral-white;
      }
    }
  }
}

// ============================================================
// MOBILE NAV
// ============================================================

#nav-toggle {
  position: fixed;
  z-index: $z-index-320-global-nav-main;
  top: 0;
  left: 0;
  width: rem-calc(50px);
  min-width: unset;
  height: rem-calc(49px);
  min-height: rem-calc(49px);
  border: none;
  background: $color-neutral-white;

  .nav-toggle-bar,
  .nav-toggle-bar::after,
  .nav-toggle-bar::before {
    position: absolute;
    top: 50%;
    left: rem-calc(10px);
    width: rem-calc(30px);
    height: rem-calc(2px);
    background-color: $color-primary-nm-blue;
    content: '';
    transform: translateY(-50%);
    transition: all 0.2s ease;
  }

  .nav-toggle-bar {
    margin-top: 0;
  }
  .nav-toggle-bar::after {
    margin-top: rem-calc(8px);
    margin-left: rem-calc(-10px);
  }
  .nav-toggle-bar::before {
    margin-top: rem-calc(-8px);
    margin-left: rem-calc(-10px);
  }

  &.is-active .nav-toggle-bar {
    background: transparent;
  }

  &.is-active .nav-toggle-bar::after,
  &.is-active .nav-toggle-bar::before {
    margin-top: 0;
    background: $color-neutral-gray-59;
  }

  &.is-active .nav-toggle-bar::after {
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  &.is-active .nav-toggle-bar::before {
    margin-left: rem-calc(-10px);
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  @include breakpoint(large) {
    display: none;
  }
}

.nmx-mobile-nav-overlay {
  position: fixed;
  z-index: $z-index-310-global-nav-overlay;
  display: none;
  // TODO: nest inside container (.nmx-nav-primary) or keep near mobile nav styles?
  width: 100%;
  height: 100%;
  background-color: $color-neutral-black;
  opacity: 0.6;
  body.is-mobile-active & {
    display: block;
  }
  @include breakpoint(large) {
    display: none;
  }
}

#nmx-mobile-nav-container {
  position: absolute;
  z-index: $z-index-330-global-nav-primary;
  top: rem-calc(50px);
  left: -100%;
  // TODO: use this for entire mobile nav activation transition? // make sure to position: fixed logo, toggle and login button
  display: block;
  width: 100%;

  animation: mobileNavClose 0.15s both;
  background-color: $color-neutral-white;
  overflow-y: auto;
  @include breakpoint(large) {
    position: relative;
    top: 0;
    left: auto;
    max-width: none;
    transition: none;
  }
  & > .nmx-row {
    @include breakpoint(large) {
      max-width: 90rem;
      margin: 0 auto;
    }
  }
  @keyframes mobileNavClose {
    0%,
    5% {
      transform: translateX(rem-calc($mobile-nav-width));
    }
    75%,
    100% {
      transform: translateX(0);
    }
  }
  body.is-mobile-active & {
    position: fixed;
    animation: mobileNavOpen 0.15s both;
    @keyframes mobileNavOpen {
      0%,
      5% {
        transform: translateX(0);
      }
      75%,
      100% {
        transform: translateX(100%);
      }
    }
  }
}

// ============================================================
// SEARCH
// ============================================================

// TODO: test move within .nmx-nav-link ?

#nmx-nav-link-utility-search {
  @include breakpoint($max: large) {
    // TODO: this is probably cheap doing desktop-first here, let's properly reset these styles on desktop
    // z-index: $z-index-430-search-controls;
    // position: absolute;
    // background: none;
    // margin-top: rem-calc(16px);
    // width: rem-calc(40px);
    // height: rem-calc(50px);
    // min-width: unset;
    // min-height: unset;
    // margin-left: rem-calc(17px);
    display: none;
  }

  display: flex;
  width: rem-calc(75px);
  min-width: rem-calc(75px);
  align-items: center;
  padding: 0;
  border: none;
  background-color: $color-neutral-white;
  border-radius: 0;
  color: $color-section-background-blue;
  text-indent: rem-calc(-9999px);
  @include breakpoint(large) {
    height: rem-calc(75px);
    // ie flexbox hack/fix
    @media all and (-ms-high-contrast: none) {
      position: absolute;
      top: 0;
      display: flex;
      align-items: center;
    }
  }
  .nmx-icon-search {
    position: absolute;
    right: 0; // safari
    width: 100%;
    height: rem-calc(30px);
    @media all and (-ms-high-contrast: none) {
      left: 0;
    }
  }
  &:hover {
    .nmx-icon-search {
      .icon-fill {
        fill: $color-system-cta-yellow;
      }
    }
  }
}

#nmx-nav-link-search-container {
  position: absolute;
  z-index: $z-index-330-global-nav-primary;
  top: 0;
  right: 0;
  width: rem-calc(75px);
  height: max-content;
  padding: 0;
  border-bottom: none;

  @include breakpoint(769, 810) {
    width: rem-calc(40px);
  }

  @include breakpoint(large) {
    // z-index: inherit; // TODO: test 'inherit' in IE
    top: rem-calc(40px);
    // right: inherit; // TODO: test 'inherit' in IE
    // ie flexbox hack/fix
    // @media all and (-ms-high-contrast: none) {
    //   right: 0;
    // }
  }
}

.video-container {
  // TODO: this can be moved to defaults
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
