// default svg fill colors

// TODO: each SVG file should get similar treatment (svg masks converted to shape layers)
// TODO: if possible (for less complicated (one-color) icons), convert inline styles to a class

.nmx-icon {
  // profile-outline
  &-profile-outline {
    .icon-fill {
      fill: $color-blue-on-blue-heading-accent;
    }
  }
}
