// constants
// $mobile-nav-width: 320px;

.nmx-search {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  overflow: hidden;
  padding: 0;
  margin: 0;
  &.has-results {
    z-index: $z-index-420-search-main;
  }
  @include breakpoint(large) {
    display: none;
  }

  .nmx-container__search {
    position: fixed;
    display: block;
    width: 100%; // rem-calc($mobile-nav-width);
    margin-left: 0; // -100%; // rem-calc(-$mobile-nav-width);
    background-color: $color-neutral-gray-99;
    &.has-results {
      z-index: $z-index-421-search-main;
      overflow-y: visible;
      @include breakpoint(large) {
        padding-bottom: rem-calc(29px); // desktop search results bottom gap fix
      }
    }
    @include breakpoint(large) {
      z-index: $z-index-420-search-main;
      width: 100%;
      max-width: none;
      margin-left: 0;
      background-color: $color-neutral-white;
      & > .nmx-row {
        max-width: 90rem;
        margin: 0 auto;
      }
    }
  }

  &-overlay {
    position: fixed;
    z-index: $z-index-410-search-overlay;

    display: none;
    width: 200%;
    height: 100%;
    background-color: $color-neutral-black;
    opacity: 0.4;
    &.has-results {
      display: block;
    }

    @include breakpoint(large) {
      display: block;
    }
  }

  &-form {
    padding: rem-calc(16px);

    @include breakpoint(large) {
      padding: rem-calc(50px 0);
    }

    legend {
      display: none;
    }
    label {
      margin-bottom: rem-calc(20px);
      color: $color-neutral-gray-59;
    }
    //  custom search field clear button
    .nmx-icon-search-field-clear {
      position: relative;
      top: rem-calc(-60px);
      right: 0;
      right: rem-calc(20px);
      display: none;
      border: 1px solid transparent;
      background-color: transparent;
      color: $color-system-cta-blue;
      cursor: pointer;
      font-size: rem-calc(20px);
      font-weight: $weight-regular;
      line-height: rem-calc(30px);
      visibility: hidden;
      @include breakpoint(large) {
        display: block;
        float: right;
      }
    }
  }

  &.is-open {
    @include breakpoint(large) {
      z-index: $z-index-420-search-main;
      display: block;
    }
  }

  &-results {
    position: relative;
    z-index: $z-index-420-search-main;
    display: block;
    overflow: auto;
    width: 100%;
    // min-height: rem-calc(60px);

    background-color: $color-neutral-gray-99;
    @include breakpoint(large) {
      margin-bottom: rem-calc(16px);
      background-color: $color-neutral-white;
    }

    &__zero-matches-message {
      padding: 0 0.75rem 1rem;
      @include breakpoint(large) {
        padding-bottom: 2.5rem;
      }
    }
  }

  &-result-item {
    padding-right: rem-calc(12px);
    padding-bottom: rem-calc(20px);
    padding-left: rem-calc(12px);
    margin-bottom: rem-calc(20px);
    @include breakpoint(large) {
      padding-right: 0;
      padding-bottom: rem-calc(40px);
      padding-left: 0;
      margin-bottom: rem-calc(40px);
    }

    &:not(:last-child) {
      border-bottom: 1px solid $color-neutral-gray-59;
    }

    a {
      &:hover {
        text-decoration: none;
        .nmx-search-result-item-content h3 {
          color: $color-system-cta-yellow;
          text-decoration: underline;
        }
      }
    }

    // &-image {

    // }
    &-content {
      h3 {
        font-weight: $weight-light;
        text-decoration: underline;
        @include breakpoint(large) {
          text-decoration: none;
        }
      }
    }
    &-eyebrow {
      margin-bottom: 0.5rem;
      // color: $color-neutral-gray-29;
      @include font-eyebrow();
      &-category {
        font-weight: $weight-regular;
        text-transform: uppercase;
      }
      // &-date {
      // &::before {
      //   content: '- '
      // }
      // }
    }
  }
}

#nmx-search-field {
  // #nmx-search-field:-webkit-internal-autofill-selected, // TODO: research autofill style
  // #nmx-search-field:-webkit-autofill,
  // #nmx-search-field:-webkit-autofill:hover,
  // #nmx-search-field:-webkit-autofill:focus {
  position: relative;
  width: 100%;

  height: rem-calc(50px);
  padding: rem-calc(8px);
  // border: 0;
  // border-bottom: none;
  color: $color-neutral-gray-29;
  // padding: rem-calc(20px);
  font-size: rem-calc(20px);
  font-weight: $weight-300;
  line-height: rem-calc(20px);
  outline: none;
  text-indent: rem-calc(32px);

  &.has-value {
    @include breakpoint($max: large) {
      width: 75%;
    }
  }

  @include breakpoint(large) {
    // Remove default IE clear button
    &::-ms-clear {
      display: none;
    }

    // Remove default Chrome clear button
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      -webkit-appearance: none;
    }
    width: 100%;
    height: rem-calc(85px);
    padding-right: rem-calc(220px);
    border: 0;
    border-bottom: 1px solid $color-neutral-gray-54;
    font-size: rem-calc(30px);
    line-height: rem-calc(30px);

    text-indent: 0;
  }
  @include breakpoint(large) {
    padding-right: rem-calc(250px);
    font-size: rem-calc(35px);
    line-height: rem-calc(35px);
  }

  // &:focus { // removed
  //   &::placeholder {
  //     color: transparent;
  //   }
  // }

  // input:-webkit-autofill:hover,
  // input:-webkit-autofill:focus

  &::placeholder {
    color: $color-neutral-gray-59;
  }
  &:focus {
    border: 1px solid $color-system-cta-blue;
    @include breakpoint(large) {
      border-top: none;
      border-right: none;
      border-bottom-color: $color-system-cta-blue; // $color-system-cta-active-blue;
      border-left: none;
    }
  }
}

// input:-internal-autofill-selected {

// }

#nmx-search-form-container {
  padding: 0;
}

#nmx-search-form-submit {
  @include breakpoint($max: large) {
    position: absolute;
    top: 0;
    left: rem-calc(10px);
    width: rem-calc(20px);
    min-width: rem-calc(20px);
    height: rem-calc(40px);
    min-height: rem-calc(40px);
    padding-top: rem-calc(15px);
    border: none;
    margin-top: rem-calc(13px);
    margin-left: 1rem;
    background: none;
    box-shadow: none;
    text-indent: rem-calc(-9999px);
  }

  bottom: 0;
  // display: none;
  margin: 0 auto;
  margin-top: rem-calc(20px);
  float: none;
  &::before {
    position: absolute;
    left: 0;
    width: rem-calc(20px);
    height: rem-calc(30px);
    background-image: url('/template/assets/<%=templateVersion%>/images/icons/utility/search.svg'); // TODO: encode svg
    background-repeat: no-repeat;
    content: ' ';
    filter: invert(25%) sepia(96%) saturate(7900%) hue-rotate(195deg)
      brightness(100%) contrast(0); // color to #F7FAFC gray
    @include breakpoint(large) {
      display: none;
    }
  }

  @include breakpoint(large) {
    position: relative;
    bottom: rem-calc(70px);
    display: block;
    margin: 0;
    float: right;
    text-transform: uppercase;
  }
}

#nmx-search-toggle-close {
  z-index: $z-index-420-search-main;
  display: none;
  width: rem-calc(100px);
  min-width: unset;
  height: rem-calc(50px);
  min-height: rem-calc(50px);
  padding-right: rem-calc(16px);
  border: none;
  margin-top: rem-calc(15px);
  background: none;
  color: $color-system-cta-blue;
  float: right;
  font-size: rem-calc(13px);
  font-weight: $weight-regular;
  line-height: rem-calc(21px);

  &.has-value {
    @include breakpoint($max: large) {
      display: block;
    }
  }

  @include breakpoint(large) {
    display: block;
    width: rem-calc(50px);
    padding-right: rem-calc(25px); // re-inherit nmx-button padding
    background: $color-neutral-white
      url('/template/assets/<%=templateVersion%>/images/icons/utility/close.svg')
      no-repeat rem-calc(20px) 50%; // TODO: encode svg or convert to CSS
    background-size: rem-calc(16px);
    text-indent: rem-calc(-9999px);
  }
}
