//input[role='button'], // TODO: import button role
//input[type='button'], // TODO: import button type
//input[type='submit'], // TODO: import submit type

.nmx-button {
  // probably use yellow as primary and blue as secondary
  $button-color-white: $color-neutral-white;
  $button-color-blue: $color-system-cta-blue;
  $button-color-blue-hover: $color-system-cta-hover-blue;
  $button-color-blue-active: $color-system-cta-active-blue;
  $button-color-yellow: $color-system-cta-yellow;
  $button-color-yellow-hover: $color-system-cta-hover-yellow;
  $button-color-yellow-active: $color-system-cta-active-yellow;
  $button-color-blue-accent: $color-accent-blue;
  $button-color-blue-dark: $color-system-dark-blue;
  $button-color-disabled: $color-neutral-gray-41; // what gray?
  $button-color-disabled-background: $color-neutral-gray-95; // what gray?
  $button-color-disabled-border: $color-neutral-gray-58; // what gray?
  $button-color-selected-background: $color-neutral-gray-95; // what gray?
  $button-color-selected-tertiary: $color-primary-nm-blue;
  $button-color-box-shadow: $color-neutral-gray-95; // what gray?
  $button-color-disabled-secondary: $color-neutral-gray-95; // what gray?

  $button-transition: 100ms;

  @media print {
    display: none !important;
  }
  min-width: rem-calc(200px);
  max-width: max-content;
  height: auto;
  min-height: rem-calc(55px);
  // @include breakpoint(medium) {
  //   max-height: rem-calc(40px);
  // }
  padding: rem-calc(11px 25px 9px);
  border: 1px solid $button-color-yellow;
  background-clip: padding-box; // ff fix
  background-color: $button-color-yellow;
  border-radius: rem-calc(2px);
  color: $button-color-blue-accent;
  cursor: pointer;
  font-size: rem-calc(16px);
  font-weight: 500;
  letter-spacing: $font-letter-spacing;
  line-height: (19 / 16);

  -webkit-tap-highlight-color: transparent;
  text-align: center;
  transition: background-color $button-transition ease-out,
    box-shadow $button-transition ease-out, color $button-transition ease-out,
    border $button-transition ease-out;
  user-select: none; // TODO: confirm autoprefixer handles this

  // TODO: reconfigure focus to allow native focus ring
  // &:focus { // TODO: complete and test focus ring build
  //   outline: auto 5px -webkit-focus-ring-color;
  // }

  // ff focus fix
  &::-moz-focus-inner {
    padding: 0;
    border: none;
  }

  // ff focus fix
  // FF Focus Ring takes into account inner text indented at -9999px, TODO: auto-set aria-label to "working" and make sure button height is retained when inner text is removed, will come back to this.
  &:-moz-focusring {
    outline: 2px solid $button-color-blue-accent;
    outline-offset: rem-calc(2px);
  }

  // base button states / .is-active is the JS alternative way for us to trigger the hover state on a button dynamically, if need be.
  &:hover,
  &.is-active {
    border: 1px solid $button-color-yellow-hover;
    // text-decoration: underline; // TODO: configure standardized hover state
    background-color: $button-color-yellow-hover;
    box-shadow: 0 2px 8px 0 rgba($color-neutral-black, 0.1);
  }

  &:focus {
    background-color: $button-color-yellow; // this will override the hover state when it's focused.
    outline: auto 5px -webkit-focus-ring-color; // overwrite nmcom styles
  }

  &:active {
    border: 1px solid $button-color-yellow-active;
    background-color: $button-color-yellow-active;
    box-shadow: 0 2px 8px 0 rgba($color-neutral-black, 0.1);
  }

  // begin sub classes
  // &--primary {} // same as default

  &--secondary {
    // this would be the blue CTA
    border: 1px solid $button-color-blue;
    //height: rem-calc(38px);
    background-color: $button-color-blue;
    box-shadow: unset;
    color: $color-neutral-white;

    // secondary button states
    &:hover,
    &.is-active {
      border-color: $button-color-blue-hover;
      background-color: $button-color-blue-hover;
      box-shadow: unset;
    }

    &:active {
      border-color: $button-color-blue-active;
      background-color: $button-color-blue-active;
      box-shadow: unset;
    }

    &:focus {
      border-color: $button-color-blue-hover;
      background-color: $button-color-blue-hover; // TODO: configure all secondary button states
      box-shadow: unset;
    }
  }

  &--tertiary {
    // TODO: this would be a stand-alone button, same click area, but no visible background
    // This could take the place of the font-a mixin
    border: 1px solid $button-color-blue;
    //height: rem-calc(38px);
    background-color: transparent;
    box-shadow: unset;
    color: $button-color-blue;

    // tertiary button states
    &:hover,
    &.is-active {
      border-color: $button-color-blue-hover;
      background-color: transparent;
      box-shadow: unset;
      color: $button-color-blue-hover;
    }

    &:active {
      border-color: $button-color-blue-active;
      background-color: transparent;
      box-shadow: unset;
      color: $button-color-blue-active;
    }

    &:focus {
      border-color: $button-color-blue-hover;
      background-color: transparent; // TODO: configure all tertiary button states
      box-shadow: unset;
      color: $button-color-blue-hover;
    }
  }

  &--quarternary {
    // TODO: ideally this would be tertiary (with 2 versions of primary (blue + yellow) and secondary being just the border version)
    border: none;
    background-color: transparent;
    box-shadow: unset;
    color: $button-color-blue;

    // quarternary button states
    &:hover,
    &.is-active {
      border: none;
      background-color: transparent;
      box-shadow: unset;
      color: $button-color-blue-hover;
    }

    &:active {
      border: none;
      background-color: transparent;
      box-shadow: unset;
      color: $button-color-blue-active;
    }

    &:focus {
      border: none;
      background-color: transparent;
      box-shadow: unset;
      color: $button-color-blue-hover; // TODO: configure all quarternary button states
    }
  }

  &--disabled {
    border: 1px solid $button-color-disabled-border;
    background-color: $button-color-disabled-background;
    box-shadow: unset;
    color: $button-color-disabled;
    cursor: default;

    // disabled button states
    &:hover,
    &.is-active,
    &:active,
    &:focus {
      border-color: $button-color-disabled-border;
      background-color: $button-color-disabled-background;
      box-shadow: unset;
    }
  }
}
