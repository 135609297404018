@import './legacy/variables/colors.variable';

.nmx-footer {
  &-section {
    padding-top: 2rem;
    padding-bottom: 2rem;
    text-align: left;
    // @include breakpoint(medium) {
    //   padding-top: rem-calc(50px); // TODO: come back and revisit (with updated grid items - used in coronavirus-response) when it's ready to do home page test again
    //   padding-bottom: rem-calc(50px);
    // }
    & > .nmx-row {
      // ie flexbox hack/fix
      @media all and (-ms-high-contrast: none) {
        width: 100%;
      }
    }
  }

  &__disclaimer {
    padding-top: 0;
    padding-bottom: 1rem;
    border-bottom: 1px solid $color-neutral-gray-59;
    margin-top: 2rem;
    &-content {
      &#{&} {
        padding-top: 2rem;
        padding-bottom: 2rem;
      }
      @include breakpoint(medium) {
        column-count: 2;
      }

      .footnote-item {
        &:not(:last-child) {
          padding-bottom: 1rem;
        }
      }

      &-custom,
      &-general {
        border-top: 1px solid $color-neutral-gray-59;
      }
    }

    .nmx-link {
      font-size: (rem-calc(14px * 0.8));
      @include breakpoint(xsmall) {
        font-size: (rem-calc($font-size-base * 0.8));
      }
      // @include breakpoint(xlarge) {
      //   font-size: (rem-calc($font-size-base * 0.8));
      // }
    }
  }

  &-logo {
    top: 0;
    left: 0;
    display: inline-block;
    width: rem-calc(250px);
    max-width: rem-calc(250px);
    height: rem-calc(30px);
    align-self: center;
    margin: 0 auto;
    // @include breakpoint(medium) {
    background-image: url('/template/assets/<%=templateVersion%>/images/logo-horizontal-navy.svg'); // TODO: encode svg
    background-position-x: 0;
    background-repeat: no-repeat;
    background-size: cover;
    text-indent: rem-calc(-9999px);
    vertical-align: text-top;

    &-container {
      position: relative;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      padding: 0 0 2rem;
      margin-left: 0;
    }
  }

  &__nav-container {
    padding-bottom: 0;
  }

  &-nav {
    &-link {
      &#{&} {
        color: $color-neutral-gray-29;
        font-size: rem-calc(14px);
        line-height: rem-calc(22px);
      }

      &-container {
        margin-bottom: rem-calc(10px);
      }
      &-button {
        border: none;
        background-color: transparent;
        text-align: left;
        padding: 0;
        cursor: pointer;
        &:hover {
          color: $nm-blue;
          text-decoration: underline;
        }
      }
    }
    .nmx-col {
      &:last-child {
        @include breakpoint(medium) {
          margin-left: 0;
        }
      }
    }
  }

  &-social {
    &__links-heading {
      &#{&} {
        display: none;
        @include font-p();
        margin-bottom: rem-calc(8px);
        @include breakpoint(medium) {
          display: block;
        }
      }
    }
    &__links-container {
      &#{&} {
        margin-top: 2rem;
        text-align: center;
        @include breakpoint(medium) {
          margin-top: 0;
          text-align: left;
        }
      }
    }
    &__link {
      display: block;
      &-item {
        &.social-link-x {
          vertical-align: top;
        }
        display: inline-block;
        &:not(:last-child) {
          margin-right: rem-calc(5px);
        }
      }

      &.social-link-x {
        display: flex;
        width: rem-calc(30px);
        height: rem-calc(30px);
        align-items: center;
        background-color: $color-neutral-gray-80;
        border-radius: 50%;
        &:hover,
        &:focus {
          background-color: $color-system-cta-hover-blue;
        }
        .nmx-icon {
          width: 100%;
          .icon-fill {
            fill: $color-neutral-white;
          }
        }
      }

      .nmx-icon {
        .icon-fill {
          fill: $color-neutral-gray-80;
        }
      }

      &:not(.social-link-x) {
        &:hover,
        &:focus {
          .nmx-icon {
            .icon-fill {
              fill: $color-system-cta-hover-blue;
            }
          }
        }
      }
    }
  }

  &-fafa {
    &__heading {
      &#{&} {
        display: inline-block;
        margin-right: 1rem;
        font-weight: $weight-light;
      }
    }
    &__container {
      margin-top: 2rem;
      @include breakpoint(large) {
        margin-top: 0;
      }
      .nmx-link {
        display: inline-block;
      }
    }
  }
  #ot-sdk-btn.ot-sdk-show-settings.nmx-override {
    color: $color-neutral-gray-29;
    border: unset;
    height: unset;
    white-space: unset;
    word-wrap: unset;
    padding: unset;
    font-size: 14px;
    line-height: 22px;
    cursor: pointer;
    transition: unset;
    font-weight: 400;
    text-transform: unset;
    &:hover {
      color: $color-system-cta-hover-blue;
      background-color: unset;
    }
  }
}
